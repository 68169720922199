import React, { Component } from 'react';
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import LoginPage from '../page/LoginPage';
import NotFoundPage from '../page/NotFoundPage';
import PacientValidationPage from '../page/PacientValidationPage';
import PasswordResetPage from '../page/PasswordResetPage';

export default class LoginNavigator extends Component {
    render() {
        return (
            <BrowserRouter forceRefresh={true}>
                <Switch>
                    <Route exact path="/" render={() => <LoginPage/> } />
                    <Route path="/cpf" render={() => <PacientValidationPage/> } />
                    <Route path="/password_reset" render={() => <PasswordResetPage/> } />
                    <Route path="/404" render={() => <NotFoundPage/> } />
                    <Redirect to="/404" />
                </Switch>
            </BrowserRouter>
        )
    }
}
