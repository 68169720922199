import Firestore from '../api/firebase/Firestore';

export default class NotificationHelper {

    static async send(title, body, data, token, id_user, id_notification_sended = null) {

        const message = {
            to: token,
            title: title,
            body: body,
            data: data,
            sound: 'default',
            priority: 'normal',
        };

        let color = '#000000';
        let icon = 'bell';

        let notificationData = {
            title: message.title ? message.title : 'Sem título',
            body: message.body ? message.body : 'Sem corpo',
            data: message.data ? message.data : { icon, color },
            date: new Date(),
            platform: false,
            viewed: false,
            id_user: id_user,
            id_notification_sended: id_notification_sended,
        }

        await Firestore.insert(notificationData, 'notification');

        await fetch('https://exp.host/--/api/v2/push/send', {
            method: 'POST',
            mode: 'no-cors',
            headers: {
                'Accept': 'application/json',
                'Accept-encoding': 'gzip, deflate',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(message),
        });
    }
}