import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button, InputLabel } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import DefaultSelect from '../components/DefaultSelect';
import SessionHelper from '../helper/SessionHelper';
import MedicalSpecialties from '../constants/MedicalSpecialties';
import IosSwitch from '../components/IosSwitch';
import InputMask from "react-input-mask";
import FaceIcon from '@material-ui/icons/Face';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Storage from '../api/firebase/Storage';
import moment from 'moment';

export default class DoctorPage extends Component {

    state = {
        docs: [],
        name: '',
        email: '',
        phone: '',
        crm: '',
        especiality: '',
        active: true,
        loading: true,
        loadingModal: false,
        addModal: false,
        productImage: '',
        blobImage: null,
        photoModalURI: null,
        photoModal: false,
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('user').where('id_company', '==', SessionHelper.getData().id_company).where('type', '==', 'doctor').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    async componentDidMount() {

        await this.getDocs();
    }

    async addDoctor() {

        if (this.state.name && this.state.email) {

            let data = {
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone.replace(/[^\d]/g, ''),
                crm: this.state.crm,
                especiality: this.state.especiality,
                type: 'doctor',
                id_company: SessionHelper.getData().id_company,
                active: this.state.active,
                date: new Date(),
            };

            try {

                this.setState({ loadingModal: true });

                if (this.state.blobImage) {

                    let ref = await Storage.uploadFile('doctor_images', this.state.blobImage);
                    let downloadPath = await Storage.getFileFromRef(ref);
        
                    if (!downloadPath) {
                        throw new Error('upload error');
                    }

                    data.photo = downloadPath;
                }

                await Firestore.insert(data, 'user');

                toast.success("Cadastrado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.setState({ loadingModal: false, addModal: false, name: '', email: '', phone: '', crm: '', speciality: '', blobImage: null, productImage: '', active: true });
                await this.getDocs();

            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    async uploadPhoto() {

        if (this.state.editId && this.state.blobImage) {

            try {

                this.setState({ loadingModal: true });

                let ref = await Storage.uploadFile('teacher_images', this.state.blobImage);
                let downloadPath = await Storage.getFileFromRef(ref);
        
                if (!downloadPath) {
                    throw new Error('upload error');
                }

                await Firestore.update({ photo: downloadPath }, 'user', this.state.editId);
    
                toast.success("Imagem atualizada com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ photoModal: false, loadingModal: false });

                this.getDocs();
    
            } catch (error) {
                toast.error("Houve um problema ao salvar a imagem", {
                    position: toast.POSITION.TOP_RIGHT
                });


                this.setState({ loadingModal: false });
            }
        }
    }

    photoModal() {

        if (this.state.photoModal) {
            return (
                <div style={{}}>
                    
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        { !this.state.photoModalURI ? <p style={{ textAlign: 'center', color: 'grey', paddingTop: 100 }}>Nenhuma imagem adicionada</p> : null }
                        <img style={{ paddingTop: 15, paddingBottom: 15, height: 350, boxShadow: Colors.boxShadow, marginBottom: 20 }} src={this.state.photoModalURI}/>
                    </div>

                    <input
                    accept="image/*"
                    style={{display: 'none'}}
                    id="contained-button-file"
                    type="file"
                    onChange={(v) => { this.setState({ blobImage: v.target.files[0], photoModalURI: URL.createObjectURL(v.target.files[0]) }) }}
                    />
                    <label htmlFor="contained-button-file">
                        <Button startIcon={<CloudUploadIcon/>} component="span" style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '100%'}} variant={'contained'}>{'UPLOAD'}</Button>
                    </label>
    
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.uploadPhoto() }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ photoModal: false, blobImage: null, photoModalURI: '' }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    addModal() {
        if (this.state.addModal) {
            return (
                <div>
                    <DefaultInput required label={'Nome'} onBlur={(v) => { this.setState({ name: v }) }}/>
                    <DefaultInput required label={'E-mail'} onBlur={(v) => { this.setState({ email: v }) }}/>
                    <InputMask onChange={(v) => { this.setState({ phone: v }) }} mask="9 9999-99999999" maskPlaceholder={''} value={this.state.phone}>
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={9} label={'Telefone'} />
                    </InputMask>
                    <DefaultInput required label={'CRM'} onBlur={(v) => { this.setState({ crm: v }) }}/>
                    <div style={{ marginTop: 8, marginBottom: 15 }}>
                        <DefaultSelect search={true} searchField={'name'} id={'medical-specialties'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ especiality: v.target.value }) }} value={this.state.especiality} docs={MedicalSpecialties} label={'Especialidade'}/>
                    </div>

                    <InputLabel style={{paddingTop: 15}} htmlFor="upload-button">Imagem</InputLabel>
                    { this.state.productImage ? <img style={{ paddingTop: 15, paddingBottom: 15, height: 250, marginTop: 20, marginBottom: 20, marginLeft: '25%', boxShadow: Colors.boxShadow, border: '1px solid lightgrey' }} src={this.state.productImage}/> : <p style={{paddingTop: 30, paddingBottom: 30, textAlign: 'center', color: 'grey'}}>{'Nenhuma imagem selecionada.'}</p> }

                    <input
                    accept="image/*"
                    style={{display: 'none'}}
                    id="contained-button-file"
                    type="file"
                    onChange={(v) => { this.setState({ blobImage: v.target.files[0], productImage: URL.createObjectURL(v.target.files[0]) }) }}
                    />
                    <label htmlFor="contained-button-file">
                        <Button startIcon={<CloudUploadIcon/>} component="span" style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '100%', marginBottom: 20}} variant={'contained'}>{'UPLOAD'}</Button>
                    </label>

                    <IosSwitch label={'Ativo'} checked={this.state.active} onChange={(e) => { this.setState({ active: e }) }} />
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.addDoctor() }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        <DefaultButton onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'}/>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        
                    </div>
                </div>

                <DefaultTable
                title={'Médicos'}
                marginTop={10}
                width={window.screen.width - 290}
                actions={[
                    {
                        icon: FaceIcon,
                        tooltip: 'Foto',
                        onClick: (event, rowData) => { this.setState({ photoModal: true, photoModalURI: rowData.photo, editId: rowData.id }) } 
                    },
                ]}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Nome', field: 'name' },
                    { title: 'E-mail', field: 'email' },
                    { title: 'Telefone', field: 'phone' },
                    { title: 'CRM', field: 'crm' },
                    { title: 'Especialidade', field: 'especiality', editComponent: props => <DefaultSelect search={true} searchField={'name'} valueField={'id'} displayField={'name'} onChange={(v) => { props.onChange(v.target.value) }} value={props.value} docs={MedicalSpecialties}/> },
                    { title: 'Ativo', type: 'boolean', render: rowData => <IosSwitch disabled={true} checked={rowData.active}/>, editComponent: props => (<IosSwitch checked={props.value} onChange={(e) => { props.onChange(e) }} />), field: 'active' },
                    { title: 'Data Cadastro', field: 'date', editable: false, render: rowData => <div>{moment(rowData.date.toDate()).format('DD/MM/YYYY HH:mm')}</div> },
                ]}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    newData.date = oldData.date;

                    let prev = this.state.docs;
                    prev[prev.indexOf(oldData)] = newData;

                    this.setState({ docs: prev });

                    if (oldData.id) {
                        
                        let data = {
                            name: newData.name,
                            email: newData.email,
                            phone: newData.phone,
                            crm: newData.crm,
                            especiality: newData.especiality,
                            active: newData.active,
                        };

                        await Firestore.update(data, 'user', oldData.id);
                    }

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                onRowDelete={ async (oldData) => {

                    let prev = this.state.docs;
                    prev.splice(prev.indexOf(oldData), 1);
                    
                    this.setState({ docs: prev });

                    if (oldData.id) {

                        await Firestore.delete('user', oldData.id);

                        toast.success("Removido com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        return prev;
                    }
                }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Usuário'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.photoModal()} title={'Foto'} onClose={() => { this.setState({ photoModal: false, blobImage: null, photoModalURI: '' }) }} open={this.state.photoModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
