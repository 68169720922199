import React, { Component, forwardRef } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button, FormLabel, FormGroup } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import IosSwitch from '../components/IosSwitch';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import AddIcon from '@material-ui/icons/Add';

export default class PermissionPage extends Component {

    state = {
        docs: [],
        name: '',
        routes: {
            dashboard: true,
            user: true,
            permission: true,
            newsletter: true,
            notification: true,
            pacient: true,
            establishment: true,
            coupon: true,
            images: true,
            integration: true,
            specialties_and_categories: true,
        },
        routesSubtitle: {
            dashboard: 'Dashboard',
            user: 'Usuários',
            permission: 'Permissões',
            newsletter: 'Notícias',
            notification: 'Notificações',
            pacient: 'Pacientes',
            establishment: 'Conveniados',
            coupon: 'Cupons',
            images: 'Imagens',
            integration: 'Integrações',
            specialties_and_categories: 'Categorias e Especialidades',
        },
        loading: true,
        loadingModal: false,
        addModal: false,
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('permission_group').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let routes = doc.data().routes;

            let data = {
                name: doc.data().name,
                id: doc.id
            };

            Object.keys(this.state.routes).forEach(key => {
                data[key] = routes[key]
            })

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    componentDidMount() {

        this.getDocs();
    }

    async addPermission() {

        if (this.state.name && this.state.routes) {

            let data = {
                name: this.state.name,
                routes: this.state.routes,
                id_company: SessionHelper.getData().id_company,
            };

            try {

                this.setState({ loadingModal: true });

                await Firestore.insert(data, 'permission_group');

                toast.success("Cadastrado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.setState({ loadingModal: false, addModal: false, name: ''});
                await this.getDocs();

            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }
        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    addModal() {
        return (
            <div>
                <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} label={'Nome'} onBlur={(v) => { this.setState({ name: v }) }}/>
                <div style={{paddingTop: 18}}>
                    <FormLabel component="legend">Menus</FormLabel>
                    <FormGroup>
                        {Object.keys(this.state.routes).map(key =>
                            <IosSwitch label={this.state.routesSubtitle[key]} checked={this.state.routes[key]} onChange={(v) => { let routes = this.state.routes; routes[key] = v; this.setState({ routes: routes }) }}/>
                        )}
                    </FormGroup>
                </div>

                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.addPermission() }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    getColumns() {
        let columns = [
            { title: 'Id', field: 'id', hidden: true },
            { title: 'Nome', field: 'name' }
        ];

        Object.keys(this.state.routes).forEach(key => {
            columns.push({ title: this.state.routesSubtitle[key], type: 'boolean', render: rowData => <IosSwitch disabled={true} checked={rowData[key]}/>, editComponent: props => (<IosSwitch checked={props.value} onChange={(e) => { props.onChange(e) }} />), field: key });
        });

        return columns;
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        <DefaultButton leftIcon={<AddIcon/>} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'}/>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        
                    </div>
                </div>

                <DefaultTable
                title={'Grupo de Permissões'}
                marginTop={10}
                width={window.screen.width - 290}
                columns={this.getColumns()}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    let prev = this.state.docs;
                    const index = prev.findIndex(item => item.id === oldData.id);
                    prev[index] = newData;

                    this.setState({ docs: prev });
                    this.forceUpdate();

                    if (oldData.id) {

                        let data = {
                            name: newData.name,
                            routes: {}
                        };

                        Object.keys(this.state.routes).forEach(key => {
                            data.routes[key] = newData[key] ? newData[key] : false;
                        });

                        await Firestore.update(data, 'permission_group', oldData.id);

                    }

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                onRowDelete={ async (oldData) => {

                    let prev = this.state.docs;
                    prev.splice(prev.indexOf(oldData), 1);
                    
                    this.setState({ docs: prev });

                    if (oldData.id) {
                        await Firestore.delete('permission_group', oldData.id);
                    }

                    toast.success("Removido com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                />

                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Grupo de Permissão'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
