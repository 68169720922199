import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import Colors from '../constants/Colors';
import { Card, Tooltip, List, ListItem } from '@material-ui/core';
import LocalNotificationHelper from '../helper/LocalNotificationHelper';
import SendIcon from '@material-ui/icons/Send';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import Firestore from '../api/firebase/Firestore';
import moment from 'moment';
import 'moment/locale/pt-br';
import SessionHelper from '../helper/SessionHelper';
import Storage from '../api/firebase/Storage';

export default class MessagePage extends Component {

    state = {
        docs: [],
        userDocs: [],
        auxDocs: [],
        text: '',
        loading: true,
        messages: [],
        user: null,
        userInterval: null,
        panelInterval: null,
        fetchingUserMessages: false,
        search: '',
    }
    
    async getPacients(fromInterval = false) {

        let query = await Firestore.customQuery('user').where('id_company', '==', SessionHelper.getData().id_company).where('type', '==', 'pacient').orderBy('last_message', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ userDocs: docs });
    }

    async getMessages(loading = false, scroll = false) {

        if (loading) {
            await this.setState({ fetchingUserMessages: true });
        }

        let query = await Firestore.customQuery('message').where('id_pacient', '==', this.state.user.id).orderBy('date', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ messages: docs });

        if (loading) {
            await this.setState({ fetchingUserMessages: false });
        }

        if (scroll && this.messagesEnd) {
            this.scrollToBottom();
        }
    }

    async componentDidMount() {

        LocalNotificationHelper.askPermission();

        await this.getPacients();

        if (this.messagesEnd) {
            this.scrollToBottom();
        }

        let interval = setInterval( async () => {
            
            this.getPacients(true);

        }, 5000);

        this.setState({ loading: false, panelInterval: interval });
    }

    componentWillUnmount() {

        if (this.state.userInterval) {
            clearInterval(this.state.userInterval);
        }

        if (this.state.panelInterval) {
            clearInterval(this.state.panelInterval);
        }

        this.setState({ userInterval: null });
    }

    clerIntervals() {
        if (this.state.userInterval) {
            clearInterval(this.state.userInterval);
        }

        if (this.state.panelInterval) {
            clearInterval(this.state.panelInterval);
        }

        this.setState({ userInterval: null });
    }

    scrollToBottom = () => { this.messagesEnd.scrollIntoView({ behavior: "smooth" }) }

    async userClick(user) {

        if (this.input) { this.input.value = '' }

        if (this.state.userInterval) {
            clearInterval(this.state.userInterval);
        }

        this.getMessages(true, true);

        let interval = setInterval( async () => {
            
                this.getMessages();

        }, 5000);

        this.setState({ text: '', userInterval: interval, user: user });
    }

    card(user, key) {

        return (
            <ListItem button onClick={() => { this.userClick(user) }} style={{backgroundColor: this.state.user && this.state.user.id === user.id ? '#ededed' : '', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', padding: 0, borderTop: '1px solid lightgrey', borderBottom: this.state.userDocs.length - 1 === key ? '1px solid lightgrey' : '', padding: 15}}>
               
                <img style={{ height: 60, width: 60, marginRight: 20, borderRadius: 60 / 2, boxShadow: Colors.boxShadow, border: '1px solid lightgrey' }} src={user.photo ? user.photo : process.env.PUBLIC_URL + '/empty_avatar.png'}/>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div style={{fontWeight: 'bold'}}>{user.name}</div>

                    { user.email && user.phone ?

                    <div style={{}}>{`${user.phone}`}</div>

                    : null }

                    { user.state && user.city && user.zipcode ?

                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <div style={{}}>{`${user.state} • ${user.city} • ${user.zipcode}`}</div>
                    </div>

                    : null }

                    {user.new_messages ? 'new_messages' : ''}
                    <div style={{alignSelf: 'flex-end', paddingTop: 10, fontStyle: 'italic', fontSize: 12, color: 'grey'}}>{moment(user.last_message.toDate ? user.last_message.toDate() : user.last_message).locale('pt-br').calendar()}</div>
                </div>

            </ListItem>
        )
    }

    renderCards() {
        if (!this.state.userDocs.length) {

            return <div style={{marginTop: 300, textAlign: 'center', color: 'grey'}}>{'Nenhum paciente encontrado'}</div>

        } else {
            
            return (
                <List>
                {
                    this.state.userDocs.map((user, key) => {
                        return this.card(user, key);
                    })
                }
                </List>
            )
        }
    }

    orderPanel(lastMessage) {

        let users = this.state.userDocs;
        let key = users.findIndex(item => item.id === this.state.user.id);

        users[key].last_message = lastMessage;

        const sortedUsers = users.sort((a, b) => b.last_message - a.last_message);

        this.setState({ userDocs: sortedUsers });
    }

    async sendMessage() {

        if (this.state.text !== '') {
            
            let data = {
                id_pacient: this.state.user.id,
                id_user: SessionHelper.getFirebaseAuth().uid,
                message: this.state.text,
                date: new Date(),
                platform: true,
                app: false,
            };

            this.input.value = '';

            let messages = this.state.messages;
            messages.push(data);

            await this.setState({ messages: messages });

            this.orderPanel(data.date);

            if (this.messagesEnd) {
                this.scrollToBottom();
            }

            await Firestore.insert(data, 'message');
            await Firestore.update({ last_message: data.date }, 'user', data.id_pacient);
        }
    }

    async uploadFile(files) {

        const file = files[0];

        let data = {
            id_pacient: this.state.user.id,
            id_user: SessionHelper.getFirebaseAuth().uid,
            message: null,
            file: window.URL.createObjectURL(file),
            file_name: file.name,
            date: new Date(),
            platform: true,
            app: false,
        };

        let messages = this.state.messages;
        messages.push(data);

        await this.setState({ messages: messages });

        this.orderPanel(data.date);

        if (this.messagesEnd) {
            this.scrollToBottom();
        }

        let ref = await Storage.uploadFile('chat_files', file);
        let link = await Storage.getFileFromRef(ref);

        data.file = link;

        await Firestore.insert(data, 'message');
        await Firestore.update({ last_message: data.date }, 'user', data.id_pacient);
    }

    async handleSearch(term) {

        await this.setState({ search: term });

        if (term.toString().length > 2) {

            this.clerIntervals();

            let docs = this.state.userDocs;
            let result = [];

            if (docs.length) {

                this.setState({ auxDocs: docs });
            }

            result = docs.filter((user) => {

                const name = user.name ? user.name.toUpperCase() : ''.toUpperCase();
                const phone = user.phone ? user.phone.toString().toUpperCase() : ''.toUpperCase();
                const email = user.email ? user.email.toString().toUpperCase() : ''.toUpperCase();
                const cpf = user.cpf ? user.cpf.toString().toUpperCase() : ''.toUpperCase();

                const search = term.toString().toUpperCase();

                if (name.indexOf(search) > -1) {

                    return true;
                }

                if (phone.indexOf(search) > -1) {

                    return true;
                }

                if (email.indexOf(search) > -1) {

                    return true;
                }

                if (cpf.indexOf(search) > -1) {

                    return true;
                }
            });

            this.setState({ userDocs: result });

        } else if (!term.toString().length) {

            this.clearSearch();
        }
    }

    clearSearch() {

        this.searchInput.value = '';
        this.setState({ userDocs: this.state.auxDocs, search: '' });
    }

    renderTextMessage(message, key) {

        return (
            <div style={{marginTop: !this.state.messages[key - 1] || !(this.state.messages[key - 1] && this.state.messages[key - 1].platform && message.platform) ? 20 : 0, border: '1px solid lightgrey', backgroundColor: message.platform ? Colors.primary : 'rgb(245, 245, 245)', boxShadow: Colors.boxShadow, borderRadius: 10, minHeight: 48, width: '80%', marginBottom: 12, wordWrap: 'break-word'}}>
                <p style={{padding: 8, color: message.platform ? '#fff' : 'black'}}>{message.message}</p>
                <p style={{fontSize: 10, fontStyle: 'italic', paddingLeft: 15, paddingRight: 15, color: !message.platform ? 'black' : '#fff', float: 'left'}}>{`${message.platform ? `Dr' Home, ` : `${this.state.user.name}, `} ${moment(message.date.toDate ? message.date.toDate() : message.date).calendar()}`}</p>
            </div>
        )
    }

    renderFileMessage(message, key) {

        return (
            <div style={{marginTop: !this.state.messages[key - 1] || !(this.state.messages[key - 1] && this.state.messages[key - 1].platform && message.platform) ? 20 : 0, border: '1px solid lightgrey', backgroundColor: message.platform ? Colors.primary : 'rgb(245, 245, 245)', boxShadow: Colors.boxShadow, borderRadius: 10, minHeight: 48, width: '80%', marginBottom: 12, wordWrap: 'break-word'}}>
                <div onClick={() => { window.open(message.file, '_blank') }} style={{ padding: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                    <DescriptionIcon style={{fontSize: 50, color: message.platform ? '#fff' : 'black'}}/>
                    <div style={{ color: message.platform ? '#fff' : 'black', marginTop: 5 }}>{message.file_name}</div>
                </div>
                <p style={{fontSize: 10, fontStyle: 'italic', paddingLeft: 15, paddingRight: 15, color: !message.platform ? 'black' : '#fff', float: 'left'}}>{`${message.platform ? `Dr' Home, ` : `${this.state.user.name}, `} ${moment(message.date.toDate ? message.date.toDate() : message.date).calendar()}`}</p>
            </div>
        )
    }

    renderChat() {
        return (
            <div style={{width: '100%'}}>
                <div style={{height: 650, overflowY: 'scroll'}}>
                    {
                        this.state.messages.map((message, key) => {

                            return (
                                <div style={{paddingLeft: 15, paddingRight: 12, marginTop: key === 0 ? 20 : 0, paddingTop: (this.state.messages[key - 1] && !this.state.messages[key - 1].user_id) || message.platform ? 0 : 12, display: 'flex', flexDirection: message.platform ? 'row-reverse' : 'row', justifyContent: 'flex-start'}}>

                                    {!this.state.messages[key - 1] || !(this.state.messages[key - 1] && this.state.messages[key - 1].platform && message.platform || this.state.messages[key - 1].app && message.app) ? <img src={message.platform ? process.env.PUBLIC_URL + '/icon.png' : this.state.user.photo ? this.state.user.photo : process.env.PUBLIC_URL + '/empty_avatar.png'} style={{height: 50, width: 50, borderRadius: 25, border: '1px solid lightgrey', boxShadow: Colors.boxShadow, marginRight: message.platform ? 0 : 20, marginLeft: message.platform ? 20 : 0}}/> : <div style={{ height: 50, width: 50, marginRight: message.platform ? 0 : 20, marginLeft: message.platform ? 20 : 0 }}/>}

                                    { message.file ? this.renderFileMessage(message, key) : this.renderTextMessage(message, key) }

                                </div>
                            )
                        })
                    }

                    { this.state.fetchingUserMessages && !this.state.messages.length ? <DefaultLoader/> : null }

                    <div style={{ float:"left", clear: "both" }}
                        ref={(el) => { this.messagesEnd = el; }}>
                    </div>

                </div>
                <div style={{ height: 60, display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 10}}>
                    <input ref={ref => this.input = ref} onBlur={(evt) => { this.setState({ text: evt.target.value }) }} onKeyDown={ async (evt) => { if (evt.keyCode === 13 ) { await this.setState({ text: this.input.value }); this.sendMessage() } }} placeholder={'Escreva uma mensagem...'} style={{fontSize: 15, padding: 8, width: '100%', borderRadius: 15, borderColor: 'transparent', border: '0.3px solid lightgrey', }}/>
                    <Tooltip title={'Enviar Mensagem'}>
                        <SendIcon onClick={() => { this.sendMessage() }} style={{position: 'relative', right: 0, left: -38, color: Colors.primary, cursor: 'pointer' }}/>
                    </Tooltip>

                    <input
                        style={{display: 'none'}}
                        id="file-upload"
                        type="file"
                        multiple={false}
                        onChange={(v) => { this.uploadFile(v.target.files) }}
                    />
                    
                    <label htmlFor="file-upload">
                        <Tooltip title={'Anexar Arquivo'}>
                            <AttachFileIcon style={{position: 'relative', right: 0, left: -95, transform: 'rotate(45deg)', color: Colors.primary, cursor: 'pointer', marginTop: 5 }}/>
                        </Tooltip>
                    </label>
                    
                </div>
            </div>
        )
    }

    renderSearch() {

        return (
            <div style={{ marginRight: 18, marginBottom: 5, marginTop: 20}}>
                <input ref={(el) => { this.searchInput = el; }} onChange={(evt) => { this.handleSearch(evt.target.value) }} style={{width: '100%', padding: 10, fontSize: 14, borderRadius: 100, border: '0.3px solid lightgrey'}} placeholder={'Busque aqui...'}/>
                { !this.state.search ? <SearchIcon onClick={() => { if (this.searchInput) { this.handleSearch(this.searchInput.value) } }} style={{ position: 'relative', marginRight: 6, float: 'right', marginTop: -33, marginRight: 15, cursor: 'pointer' }}/> : <CloseIcon onClick={() => { if (this.searchInput) { this.handleSearch(''); } }} style={{ position: 'relative', marginRight: 6, float: 'right', marginTop: -33, marginRight: 15, cursor: 'pointer' }}/> }
            </div>
        )
    }

    render() {
        return (
            <div style={styles.container}>
                <Card style={{paddingLeft: 20, paddingRight: 20, marginBottom: 15, paddingBottom: 40, boxShadow: Colors.boxShadow}}>

                        <div style={{display: 'flex', flexDirection: 'row', overflowY: '', justifyContent: 'space-between', height: 700}}>

                            <div style={{width: '28%'}}>

                                {this.renderSearch()}

                                <div style={{overflowY: 'scroll', height: 'calc(100% - 100px)'}}>
                                    {this.state.loading ? <div style={{marginTop: 300}}><DefaultLoader/></div> : this.renderCards()}
                                </div>

                            </div>

                            <div style={{borderLeft: '0.1px lightgrey solid'}}/>

                            <div style={{width: '88%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                
                                {this.state.user ? this.renderChat() : null}

                            </div>
                        </div>

                    </Card>
            </div>
        )
    }
}

const styles = {
    container: {
        padding: 25,
    }
}