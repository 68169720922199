import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import { Card } from '@material-ui/core';
import Colors from '../constants/Colors';

export default class IntegrationPage extends Component {

    state = {
        loading: false,
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            
            <div style={{ padding: 25 }}>
                <Card style={{ width: 350, height: 350 / 2, display: 'flex', flexDirection: 'column', alignItems: 'center', boxShadow: Colors.boxShadow, cursor: 'pointer' }}>
                    <img style={{ height: 350 / 2 }} src={process.env.PUBLIC_URL + '/company_logos/clinica_nas_nuvens.png'}/>
                </Card>
            </div>
        );
    }
}
