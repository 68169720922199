import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button, TextareaAutosize, InputLabel, Tooltip, IconButton, Input } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import GeographicHelper from '../helper/GeographicHelper';
import IosSwitch from '../components/IosSwitch';
import LocationPicker from '../components/LocationPicker';
import moment from 'moment';
import InputMask from "react-input-mask";
import ImagePicker from '../components/ImagePicker';
import PhotoIcon from '@material-ui/icons/Photo';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import PhoneIcon from '@material-ui/icons/Phone';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import InfoIcon from '@material-ui/icons/Info';
import DefaultSelect from '../components/DefaultSelect';
export default class EstablishmentPage extends Component {

    state = {
        docs: [],
        type: '',
        category: null,
        name: '',
        description: '',
        especialities: [],
        services: [],
        phones: [],
        document: '',
        zipcode: '',
        state: '',
        city: '',
        street: '',
        number: '',
        complemento: '',
        neighborhood: '',
        active: true,
        loading: true,
        loadingModal: false,
        addEstablishmentModal: false,
        addMedicModal: false,
        photoModal: false,
        phoneModal: false,
        servicesModal: false,
        images: [],
        editId: null,
        coordinates: GeographicHelper.setFirebaseGeopoint(-27, -52),
        addPhone: false,
        phoneName: '',
        phoneHover: null,
        addService: false,
        serviceName: '',
        serviceHover: null,
        categories: [],
        specialties: []
    }

    async getDocs() {
        let query = await Firestore.customQuery('establishment').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs });
    }

    async getAuxDocs() {
        let query = await Firestore.customQuery('category').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let query2 = await Firestore.customQuery('specialty').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let categories = [];
        let specialties = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            categories.push(data);
        });

        query2.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            specialties.push(data);
        });

        this.setState({ categories, specialties });
    }

    async componentDidMount() {

        this.setState({ loading: true });

        await this.getDocs();
        await this.getAuxDocs();

        this.setState({ loading: false });
    }

    async addEstablishment() {

        if (this.state.name) {

            try {

                this.setState({ loadingModal: true });

                let data = {
                    type: this.state.type,
                    name: this.state.name,
                    category: this.state.category,
                    email: this.state.email || ``,
                    phones: this.state.phones,
                    document: this.state.document ? this.state.document.replace(/[^\d]/g, '') : ``,
                    active: this.state.active,
                    id_company: SessionHelper.getData().id_company,
                    date: new Date(),
                    images: this.state.images,
                    zipcode: this.state.zipcode.replace(/[^\d]/g, ''),
                    country: 'Brasil',
                    state: this.state.state,
                    city: this.state.city,
                    complemento: this.state.complemento,
                    neighborhood: this.state.neighborhood,
                    number: this.state.number.replace(/[^\d]/g, ''),
                    street: this.state.street,
                    services: this.state.services,
                };

                if (this.state.type === 'medic') {

                    data.especialities = this.state.especialities;
                    data.description = this.state.description;
                }

                await Firestore.geoInsert(data, 'establishment', GeographicHelper.getLatLngFromGeopoint(this.state.coordinates).lat, GeographicHelper.getLatLngFromGeopoint(this.state.coordinates).lng);

                await this.setState({ loadingModal: false, addEstablishmentModal: false, addMedicModal: false, category: '', name: '', email: '', phones: [], services: [], type: '', document: '', zipcode: '', state: '', city: '', complemento: '', neighborhood: '', number: '', street: '', especialities: [], active: true });
                await this.getDocs();

                toast.success('Conveniado cadastrado com sucesso');

            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                console.log(error)

                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    handleMapChange = (geodata) => {
        let state = this.state;

        if (geodata.address) {
            Object.keys(geodata.address).forEach(key => {
                if (key == 'number') {
                    state[key] = geodata.address[key].replace(/\D*/, '');
                    state['complemento'] = geodata.address[key].replace(/\d*/, '');
                } else {
                    state[key] = geodata.address[key];
                }
            });
        }
        if (geodata.coords) {
            state.coordinates = GeographicHelper.setFirebaseGeopoint(geodata.coords.lat, geodata.coords.lng);
        }

        this.setState(state);
    }

    async removePhone(key, persist) {

        let phones = this.state.phones;
        phones.splice(key, 1);

        if (this.state.editId && persist) {

            await this.setState({ loadingModal: true });

            await Firestore.update({ phones }, 'establishment', this.state.editId);
            await this.getDocs();
        }

        this.setState({ phones, loadingModal: false });
    }

    async removeService(key, persist) {

        let services = this.state.services;
        services.splice(key, 1);

        if (this.state.editId && persist) {

            await this.setState({ loadingModal: true });

            await Firestore.update({ services }, 'establishment', this.state.editId);
            await this.getDocs();
        }

        this.setState({ services, loadingModal: false });
    }

    async addPhone(persist) {

        if (this.state.phoneName.length) {

            let phones = this.state.phones;
            phones.push(this.state.phoneName);

            if (this.state.editId && persist) {

                await this.setState({ loadingModal: true, phoneName: '', addPhone: false });

                await Firestore.update({ phones }, 'establishment', this.state.editId);
                await this.getDocs();
            }

            this.setState({ phones: phones, phoneName: '', addPhone: false, loadingModal: false });

        } else {

            toast.warn('Preencha o e-mail');
        }
    }

    async addService(persist) {

        if (this.state.serviceName.length) {

            let services = this.state.services;
            services.push(this.state.serviceName);

            if (this.state.editId && persist) {

                await this.setState({ loadingModal: true, serviceName: '', addService: false });

                await Firestore.update({ services }, 'establishment', this.state.editId);
                await this.getDocs();
            }

            this.setState({ services: services, serviceName: '', addService: false, loadingModal: false });

        } else {

            toast.warn('Preencha o nome');
        }
    }

    getPhoneMask(v) {
        let mask = '(99) 9999-9999';

        if (v) {
            if (v[5] && v[5] === '9') mask = '(99) 99999-9999';
        }

        return mask;
    }

    renderPhoneAdd(persist = false) {

        return (
            <div style={{ paddingTop: 15 }}>
                <InputLabel style={{ marginBottom: 20 }}>Telefones</InputLabel>

                {
                    this.state.phones.map((email, key) => {

                        return (
                            <div onMouseEnter={() => { this.setState({ phoneHover: key }) }} onMouseLeave={() => { this.setState({ phoneHover: null }) }} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 8, padding: 15, color: Colors.primary, border: `3px solid ${Colors.primary}`, borderRadius: 8, position: 'relative' }}>
                                <PhoneIcon style={{ marginRight: 8 }} />
                                {email}
                                {this.state.phoneHover === key ? <DeleteIcon onClick={() => { this.removePhone(key, persist) }} style={{ fontSize: 25, position: 'absolute', right: 0, marginRight: 8, cursor: 'pointer', color: 'red' }} /> : null}
                            </div>
                        )
                    })
                }

                {

                    !this.state.addPhone ?

                        <Tooltip title={'Adicionar'}>
                            <IconButton onClick={(evt) => { this.setState({ addPhone: true }) }} style={{ backgroundColor: 'white', boxShadow: Colors.boxShadow, border: '1px solid lightgrey' }}>
                                <AddIcon style={{ color: Colors.primary }} />
                            </IconButton>
                        </Tooltip>

                        :
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <InputMask onChange={(v) => { this.setState({ phoneName: v }); }} mask={this.getPhoneMask(this.state.phoneName)} maskPlaceholder={''}>
                                <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={9} label={'Telefone'} />
                            </InputMask>
                            <IconButton onClick={() => { this.addPhone(persist) }} style={{ backgroundColor: 'white', boxShadow: Colors.boxShadow, border: '1px solid lightgrey', width: 50, height: 50, marginLeft: 5 }}>
                                <CheckIcon style={{ color: Colors.primary }} />
                            </IconButton>
                            <IconButton onClick={() => { this.setState({ addPhone: false, phoneName: '' }) }} style={{ backgroundColor: 'white', boxShadow: Colors.boxShadow, border: '1px solid lightgrey', width: 50, height: 50, marginLeft: 5 }}>
                                <DeleteIcon style={{ color: Colors.primary }} />
                            </IconButton>
                        </div>
                }
            </div>
        )
    }

    renderServiceAdd(persist = false) {

        return (
            <div style={{ paddingTop: 15 }}>
                <InputLabel style={{ marginBottom: 20 }}>Serviços e Procedimentos</InputLabel>

                {
                    this.state.services.map((service, key) => {

                        return (
                            <div onMouseEnter={() => { this.setState({ serviceHover: key }) }} onMouseLeave={() => { this.setState({ serviceHover: null }) }} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 8, padding: 15, color: Colors.primary, border: `3px solid ${Colors.primary}`, borderRadius: 8, position: 'relative' }}>
                                <InfoIcon style={{ marginRight: 8 }} />
                                {service}
                                {this.state.serviceHover === key ? <DeleteIcon onClick={() => { this.removeService(key, persist) }} style={{ fontSize: 25, position: 'absolute', right: 0, marginRight: 8, cursor: 'pointer', color: 'red' }} /> : null}
                            </div>
                        )
                    })
                }

                {

                    !this.state.addService ?

                        <Tooltip title={'Adicionar'}>
                            <IconButton onClick={(evt) => { this.setState({ addService: true }) }} style={{ backgroundColor: 'white', boxShadow: Colors.boxShadow, border: '1px solid lightgrey' }}>
                                <AddIcon style={{ color: Colors.primary }} />
                            </IconButton>
                        </Tooltip>

                        :

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <DefaultInput required label={'Nome'} onBlur={(v) => { this.setState({ serviceName: v }) }} />
                            <IconButton onClick={() => { this.addService(persist) }} style={{ backgroundColor: 'white', boxShadow: Colors.boxShadow, border: '1px solid lightgrey', width: 50, height: 50, marginLeft: 5 }}>
                                <CheckIcon style={{ color: Colors.primary }} />
                            </IconButton>
                            <IconButton onClick={() => { this.setState({ addService: false, serviceName: '' }) }} style={{ backgroundColor: 'white', boxShadow: Colors.boxShadow, border: '1px solid lightgrey', width: 50, height: 50, marginLeft: 5 }}>
                                <DeleteIcon style={{ color: Colors.primary }} />
                            </IconButton>
                        </div>
                }
            </div>
        )
    }

    addEstablishmentModal() {
        if (this.state.addEstablishmentModal) {
            return (
                <div>
                    <DefaultInput required label={'Nome'} onBlur={(v) => { this.setState({ name: v }) }} />
                    <div style={{ marginTop: 8 }} />
                    <DefaultSelect search={true} searchField={'title'} id={'category'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ category: v.target.value }) }} value={this.state.category} docs={this.state.categories} label={'Categoria'} />
                    <DefaultInput label={'E-mail'} onBlur={(v) => { this.setState({ email: v }) }} />

                    <InputMask onChange={(v) => { this.setState({ document: v }) }} mask="99.999.999/9999-99" value={this.state.document}>
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} minLength={14} label={'Cnpj'} />
                    </InputMask>

                    {this.renderPhoneAdd()}
                    {this.renderServiceAdd()}

                    <InputLabel style={{ marginBottom: 20, marginTop: 15 }}>{'Registro Profissional'}</InputLabel>
                    <TextareaAutosize style={{ width: '100%', borderRadius: 5, borderColor: 'lightgrey', padding: 15, fontSize: '1rem' }} rowsMax={8} rowsMin={8} onBlur={(v) => { this.setState({ description: v.target.value }) }} placeholder="Escreva uma descrição..." />

                    <div style={{ marginTop: 20 }} />

                    <LocationPicker callback={this.handleMapChange} initialCenter={GeographicHelper.getLatLngFromGeopoint(this.state.coordinates)} />

                    <div style={{ marginTop: 20 }} />

                    <InputMask disabled={true} mask="99999-999" value={this.state.zipcode}>
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={8} label={'Cep'} />
                    </InputMask>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <DefaultInput value={'Brasil'} disabled={true} label={'País'} />
                        <div style={{ paddingRight: 5, paddingLeft: 5 }} />
                        <DefaultInput disabled onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} value={this.state.state} onChange={(text) => { this.setState({ state: text }) }} label={'Estado'} />
                    </div>

                    <DefaultInput disabled onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} value={this.state.city} onChange={(text) => { this.setState({ city: text }) }} label={'Cidade'} />

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} value={this.state.street} onChange={(text) => { this.setState({ street: text }) }} label={'Rua'} />
                        <div style={{ paddingRight: 5, paddingLeft: 5 }} />
                        <div style={{ paddingRight: 5, paddingLeft: 5, width: '50%' }}>
                            <InputMask onChange={(v) => { this.setState({ number: v }) }} mask="999999999999" maskPlaceholder={''} alwaysShowMask={false} value={this.state.number}>
                                <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} label={'Número'} />
                            </InputMask>
                        </div>
                        <div style={{ paddingRight: 5, paddingLeft: 5, width: '50%' }}>
                            <DefaultInput value={this.state.complemento} onChange={(text) => { this.setState({ complemento: text }) }} label={'Complemento'} />
                        </div>
                    </div>

                    <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} value={this.state.neighborhood} onChange={(text) => { this.setState({ neighborhood: text }) }} label={'Bairro'} />

                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <ImagePicker imageWidth={250} onUploadStart={() => { this.setState({ loadingModal: true }) }} onUploadEnd={() => { this.setState({ loadingModal: false }) }} onUpload={(files) => { this.setState({ images: files }); }} images={this.state.images} folder={'establishment_images'} multiple={true} label={'Imagens'} />
                    </div>

                    <IosSwitch label={'Ativo'} checked={this.state.active} onChange={(e) => { this.setState({ active: e }) }} />
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.addEstablishment() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ addEstablishmentModal: false }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    addMedicModal() {
        if (this.state.addMedicModal) {
            return (
                <div>
                    <DefaultInput required label={'Nome'} onBlur={(v) => { this.setState({ name: v }) }} />
                    <div style={{ marginTop: 8 }} />
                    <DefaultSelect search={true} searchField={'title'} id={'category'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ category: v.target.value }) }} value={this.state.category} docs={this.state.categories} label={'Categoria'} />
                    <DefaultInput label={'E-mail'} onBlur={(v) => { this.setState({ email: v }) }} />

                    {this.renderPhoneAdd()}
                    {this.renderServiceAdd()}

                    <InputMask onChange={(v) => { this.setState({ document: v }) }} mask="99.999.999/9999-99" value={this.state.document}>
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} minLength={14} label={'Cnpj'} />
                    </InputMask>

                    <div style={{ marginTop: 8, marginBottom: 15 }}>
                        <DefaultSelect multiple search={true} searchField={'name'} id={'medical-specialties'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ especialities: v.target.value }) }} value={this.state.especialities} docs={this.state.specialties} label={'Especialidades'} />
                    </div>

                    <InputLabel style={{ marginBottom: 20 }}>{'Registro Profissional'}</InputLabel>
                    <TextareaAutosize style={{ width: '100%', borderRadius: 5, borderColor: 'lightgrey', padding: 15, fontSize: '1rem' }} rowsMax={8} rowsMin={8} onBlur={(v) => { this.setState({ description: v.target.value }) }} placeholder="Escreva uma descrição..." />

                    <LocationPicker imageWidth={250} callback={this.handleMapChange} initialCenter={GeographicHelper.getLatLngFromGeopoint(this.state.coordinates)} />

                    <div style={{ marginTop: 20 }} />

                    <InputMask disabled={true} mask="99999-999" value={this.state.zipcode}>
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={8} label={'Cep'} />
                    </InputMask>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <DefaultInput value={'Brasil'} disabled={true} label={'País'} />
                        <div style={{ paddingRight: 5, paddingLeft: 5 }} />
                        <DefaultInput disabled onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} value={this.state.state} onChange={(text) => { this.setState({ state: text }) }} label={'Estado'} />
                    </div>

                    <DefaultInput disabled onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} value={this.state.city} onChange={(text) => { this.setState({ city: text }) }} label={'Cidade'} />

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} value={this.state.street} onChange={(text) => { this.setState({ street: text }) }} label={'Rua'} />
                        <div style={{ paddingRight: 5, paddingLeft: 5 }} />
                        <div style={{ paddingRight: 5, paddingLeft: 5, width: '50%' }}>
                            <InputMask onChange={(v) => { this.setState({ number: v }) }} mask="999999999999" maskPlaceholder={''} alwaysShowMask={false} value={this.state.number}>
                                <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} label={'Número'} />
                            </InputMask>
                        </div>
                        <div style={{ paddingRight: 5, paddingLeft: 5, width: '50%' }}>
                            <DefaultInput value={this.state.complemento} onChange={(text) => { this.setState({ complemento: text }) }} label={'Complemento'} />
                        </div>
                    </div>

                    <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} value={this.state.neighborhood} onChange={(text) => { this.setState({ neighborhood: text }) }} label={'Bairro'} />

                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <ImagePicker onUploadStart={() => { this.setState({ loadingModal: true }) }} onUploadEnd={() => { this.setState({ loadingModal: false }) }} onUpload={(files) => { this.setState({ images: files }); }} images={this.state.images} folder={'establishment_images'} multiple={true} label={'Imagens'} />
                    </div>

                    <IosSwitch label={'Ativo'} checked={this.state.active} onChange={(e) => { this.setState({ active: e }) }} />
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.addEstablishment() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ addMedicModal: false }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    async updateImages(files) {

        this.setState({ images: files });
        Firestore.update({ images: files }, 'establishment', this.state.editId);
    }

    photoModal() {

        if (this.state.photoModal) {
            return (
                <div style={{}}>

                    <ImagePicker imageWidth={250} onUploadStart={() => { this.setState({ loadingModal: true }) }} onUploadEnd={() => { this.setState({ loadingModal: false }) }} onUpload={(files) => { this.updateImages(files) }} images={this.state.images} folder={'establishment_images'} multiple={true} />

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.setState({ photoModal: false, images: [] }) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '100%' }} variant={'contained'}>{'FECHAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    phoneModal() {

        if (this.state.phoneModal) {
            return (
                <div style={{}}>

                    {this.renderPhoneAdd(true)}

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.setState({ phoneModal: false, phones: [] }) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '100%' }} variant={'contained'}>{'FECHAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    servicesModal() {

        if (this.state.servicesModal) {
            return (
                <div style={{}}>

                    {this.renderServiceAdd(true)}

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.setState({ servicesModal: false, services: [] }) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '100%' }} variant={'contained'}>{'FECHAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    getCategoryLookup() {
        let categories = this.state.categories;
        let lookup = {};

        categories.forEach((category, key) => {
            lookup[category.id] = category.name;
        });

        return lookup;
    }

    getSpecialtyList(ids) {
        let specialties = this.state.specialties;
        let output = [];

        ids.forEach(id => {
            let specialty = specialties.find(spe => spe.id === id)

            if (specialty) output.push(specialty.name);
        });

        return output.length > 1 ? output.join(`, `) : output[0];
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>

                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <DefaultButton leftIcon={<HomeWorkIcon style={{ marginRight: 10 }} />} onClick={() => { this.setState({ addEstablishmentModal: true, images: [], phones: [], type: 'establishment' }) }} title={'Novo Estabelecimento'} />
                        <div style={{ marginLeft: 8 }} />
                        <DefaultButton leftIcon={<LocalHospitalIcon style={{ marginRight: 10 }} />} onClick={() => { this.setState({ addMedicModal: true, images: [], phones: [], type: 'medic' }) }} title={'Novo Profissional'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                    </div>
                </div>

                <DefaultTable
                    title={'Conveniados'}
                    marginTop={10}
                    width={window.screen.width - 290}
                    actions={[
                        {
                            icon: InfoIcon,
                            tooltip: 'Editar Serviços e Procedimentos',
                            onClick: (event, rowData) => { this.setState({ servicesModal: true, editId: rowData.id, services: rowData.services || [] }) }
                        },
                        {
                            icon: PhoneIcon,
                            tooltip: 'Editar Telefones',
                            onClick: (event, rowData) => { this.setState({ phoneModal: true, editId: rowData.id, phones: rowData.phones || [] }) }
                        },
                        {
                            icon: PhotoIcon,
                            tooltip: 'Editar Imagens',
                            onClick: (event, rowData) => { this.setState({ photoModal: true, editId: rowData.id, images: rowData.images }) }
                        },
                    ]}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Nome', field: 'name' },
                        { title: 'Categoria', field: 'category', lookup: this.getCategoryLookup() },
                        { title: 'Serviços e Procedimentos', field: 'services', render: rowData => <div>{rowData.services ? rowData.services.join(', ') : ''}</div>, editable: false },
                        { title: 'Telefones', field: 'phones', render: rowData => <div>{rowData.phones ? rowData.phones.join(', ') : ''}</div>, editable: false },
                        { title: 'Cnpj', field: 'document', render: rowData => <div>{rowData.document ? rowData.document.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : ''}</div> },
                        { title: 'Registro Profissional', field: 'description', render: rowData => <div>{rowData.description}</div> },
                        { title: 'Especialidades', field: 'especialities', render: rowData => <div>{rowData.especialities ? this.getSpecialtyList(rowData.especialities) : '--'}</div>, editComponent: props => <DefaultSelect multiple search={true} searchField={'name'} valueField={'id'} displayField={'name'} onChange={(v) => { props.onChange(v.target.value) }} value={props.value || []} docs={this.state.specialties} /> },
                        { title: 'Cep', field: 'zipcode' },
                        { title: 'Estado', field: 'state' },
                        { title: 'Cidade', field: 'city' },
                        { title: 'Rua', field: 'street' },
                        { title: 'Número', field: 'number' },
                        { title: 'Complemento', field: 'complemento' },
                        { title: 'Bairro', field: 'neighborhood' },
                        { title: 'Ativo', field: 'active', type: 'boolean', render: rowData => <IosSwitch disabled={true} checked={rowData.active} />, editComponent: props => (<IosSwitch checked={props.value} onChange={(e) => { props.onChange(e) }} />) },
                        { title: 'Data de Cadastro', editable: false, field: 'date', render: rowData => <div>{rowData.date ? moment(rowData.date.toDate ? rowData.date.toDate() : rowData.date).format('DD/MM/YYYY HH:mm') : ''}</div> },
                    ]}
                    data={this.state.docs}
                    onRowUpdate={async (oldData, newData) => {

                        newData.date = oldData.date;

                        let prev = this.state.docs;
                        prev[prev.indexOf(oldData)] = newData;

                        this.setState({ docs: prev });

                        if (oldData.id) {

                            let data = {
                                name: newData.name,
                                category: newData.category || '',
                                document: newData.document,
                                zipcode: newData.zipcode,
                                state: newData.state,
                                city: newData.city,
                                street: newData.street,
                                number: newData.number,
                                complemento: newData.complemento,
                                neighborhood: newData.neighborhood,
                                especialities: newData.especialities || [],
                                description: newData.description || '',
                                active: newData.active,
                            };

                            await Firestore.update(data, 'establishment', oldData.id);
                        }

                        toast.success("Editado com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        return prev;
                    }}
                    onRowDelete={async (oldData) => {

                        let prev = this.state.docs;
                        prev.splice(prev.indexOf(oldData), 1);

                        this.setState({ docs: prev });

                        if (oldData.id) {

                            await Firestore.delete('establishment', oldData.id);

                            toast.success("Removido com sucesso", {
                                position: toast.POSITION.TOP_RIGHT
                            });

                            return prev;
                        }
                    }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addEstablishmentModal()} title={'Novo Estabelecimento'} onClose={() => { this.setState({ addEstablishmentModal: false }) }} open={this.state.addEstablishmentModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.addMedicModal()} title={'Novo Profissional'} onClose={() => { this.setState({ addMedicModal: false }) }} open={this.state.addMedicModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.photoModal()} title={'Imagens'} onClose={() => { this.setState({ photoModal: false, images: [] }) }} open={this.state.photoModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.phoneModal()} title={''} onClose={() => { this.setState({ phoneModal: false, images: [] }) }} open={this.state.phoneModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.servicesModal()} title={''} onClose={() => { this.setState({ servicesModal: false, services: [] }) }} open={this.state.servicesModal} />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
