import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import IosSwitch from '../components/IosSwitch';
import { Card, MenuItem, Select } from '@material-ui/core';
import DefaultDashboard from '../components/dashboard/DefaultDashboard';
import Firestore from '../api/firebase/Firestore';
import Colors from '../constants/Colors';

export default class HomePage extends Component {

    state = {
        dashboard: 'default',
    }

    async componentDidMount() {

    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            
            <div style={{ padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                { this.state.dashboard === 'default' && <DefaultDashboard/> }
            </div>
        );
    }
}
