import React, { Component } from 'react';
import Colors from '../constants/Colors';
import { InputLabel, Select, MenuItem } from '@material-ui/core';
import DefaultInput from '../components/DefaultInput';

export default class DefaultSelect extends Component {

    state = {
        auxDocs: this.props.docs,
        docs: this.props.docs,
    }

    renderDefault(doc) {

        return `${doc[this.props.displayField]} ${this.props.secondaryDisplay && doc[this.props.secondaryDisplay] ? ` (${doc[this.props.secondaryDisplay]})` : ''}`
    }

    render() {
        return (
            <div style={{width: '100%'}}>
                { this.props.label && <InputLabel id={this.props.id}>{this.props.label}</InputLabel> }
                <Select
                    multiple={this.props.multiple}
                    style={{width: '100%'}}
                    labelId={this.props.id}
                    value={this.props.value}
                    disabled={this.props.disabled}
                    onChange={(v) => { this.props.onChange(v); this.clearSearch() }}>

                            { this.props.search && this.renderSearch() }
                            { this.props.allItems && this.props.allItems }
                            
                            { this.state.docs.map((doc, key) => {

                                return <MenuItem tabIndex={null} key={key} value={doc[this.props.valueField]}>{this.props.render ? this.props.render(doc) : this.renderDefault(doc)}</MenuItem>

                            }) }
                </Select>
            </div>
        )
    }

    async handleSearch(value) {

        await this.setState({ search: value });
    
        if (this.state.search.toString().length > 3) {

            let result = [];

            if (this.props.docs.length) {

                let array = this.props.docs;

                result = array.filter((company) => {

                    const field = company[this.props.searchField] ? company[this.props.searchField].toString().toUpperCase() : ''.toUpperCase();
                    const search = this.state.search.toString().toUpperCase();

                    if (field.indexOf(search) > -1) {

                        return true;
                    }
                });
            }

            this.setState({ docs: result });

        } else if (this.state.search.toString().length === 0) {

            this.clearSearch();
        }
    }

    clearSearch() {
        this.setState({ docs: this.state.auxDocs })
    }

    renderSearch() {
        return <DefaultInput onChange={(v) => { this.handleSearch(v) }} style={{ marginTop: 10, color: Colors.primary, paddingTop: 10, paddingBottom: 10, marginLeft: 20, width: '92%', paddingRight: 25 }} label={'Pesquise aqui...'}/>
    }
}
