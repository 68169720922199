import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button, FormLabel } from '@material-ui/core';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import AddIcon from '@material-ui/icons/Add';
import Firestore from '../api/firebase/Firestore';
import moment from 'moment';
import IosSwitch from '../components/IosSwitch';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import SyncIcon from '@material-ui/icons/Sync'
import Functions from '../api/firebase/Functions';
import ZipCodeHelper from '../helper/ZipCodeHelper';
import InputMask from 'react-input-mask';

export default class PacientPage extends Component {

    state = {
        docs: [],
        name: '',
        email: '',
        phone: '',
        cpf: '',
        zipcode: '',
        street: '',
        number: '',
        neighbourhood: '',
        complement: '',
        state: '',
        city: '',
        active: true,
        loading: true,
        loadingModal: false,
        addModal: false,
        inputError: false,
    }

    async getDocs() {
        let query = await Firestore.customQuery('user').where('id_company', '==', SessionHelper.getData().id_company).where('type', '==', 'pacient').orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs });
    }

    async componentDidMount() {

        await this.getDocs();

        this.setState({ loading: false });
    }

    async addPacient() {

        if (!this.state.inputError) {

            let data = {
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone.replace(/[^\d]/g, ''),
                cpf: this.state.cpf.replace(/[^\d]/g, ''),
                state: this.state.state,
                city: this.state.city,
                zipcode: this.state.zipcode.replace(/[^\d]/g, ''),
                street: this.state.street,
                number: this.state.number,
                neighbourhood: this.state.neighbourhood,
                complement: this.state.complement,
                id_company: SessionHelper.getData().id_company,
                date: new Date(),
                active: this.state.active,
                type: 'pacient',
                app_login: false,
            };

            try {

                this.setState({ loadingModal: true });

                await Firestore.insert(data, 'user');

                toast.success("Cadastrado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.setState({ addModal: false, name: '', email: '', phone: '', cpf: '', zipcode: '', street: '', number: '', neighbourhood: '', complement: '', active: true });
                await this.getDocs();

                this.setState({ loadingModal: false });

            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn("Preencha todos os campos corretamente", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    async zipCodeFetch(zipCode) {

        if (zipCode.length) {

            let response = await ZipCodeHelper.getAddress(zipCode.replace(/[^\d]/g, ''));
        
            if (response !== false) {
    
                this.setState({ street: response.logradouro, city: response.localidade, state: response.uf, neighbourhood: response.bairro, complement: response.complemento })
    
            } else {
    
                this.setState({ street: '', city: '', state: '', neighbourhood: '', complement: '' })
            }
        }
    }

    addModal() {

        if (this.state.addModal) {

            return (
                <div>
                    <FormLabel style={{paddingTop: 28, paddingBottom: 18}} component="legend">Dados do Paciente</FormLabel>
                    <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ name: v }) }} label={'Nome'}/>
                    <DefaultInput required email onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ email: v }) }} label={'E-mail'}/>
                    
                    <InputMask onBlur={(v) => { this.setState({ phone: v }) }} mask="(99) 9 9999-99999999" maskPlaceholder={''}>
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={11} label={'Telefone'} />
                    </InputMask>

                    <InputMask onBlur={(v) => { this.setState({ cpf: v }) }} mask="999.999.999-99" maskPlaceholder={''}>
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={11} label={'Cpf'} />
                    </InputMask>

                    <FormLabel style={{paddingTop: 28, paddingBottom: 18}} component="legend">Endereço</FormLabel>
                    <div style={{ color: 'orange', display: 'flex', flexDirection: 'row', fontWeight: 'bold', marginBottom: 10 }}>
                        <InfoRoundedIcon style={{ fontSize: 20, marginRight: 8 }}/>
                        <div>{'Informe o CEP para auto-completar o endereço.'}</div>
                    </div>
                    <InputMask onBlur={(v) => { this.zipCodeFetch(v) }} onChange={(v) => { this.setState({ zipcode: v }) }} mask="99999-999" value={this.state.zipcode}>
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={8} label={'Cep'} />
                    </InputMask>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '50%', paddingRight: 5 }}><DefaultInput value={this.state.state} disabled={true} label={'Estado'}/></div>
                        <div style={{ width: '50%', paddingLeft: 5 }}><DefaultInput value={this.state.city} disabled={true} label={'Cidade'}/></div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '70%', paddingRight: 5 }}><DefaultInput value={this.state.street} onChange={(v) => { this.setState({ street: v }) }} label={'Rua'}/></div>
                        <div style={{ width: '30%', paddingLeft: 5 }}><DefaultInput value={this.state.number} onChange={(v) => { this.setState({ number: v }) }} label={'Número'}/></div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '50%', paddingRight: 5 }}><DefaultInput value={this.state.neighbourhood} onChange={(v) => { this.setState({ neighbourhood: v }) }} label={'Bairro'}/></div>
                        <div style={{ width: '50%', paddingLeft: 5 }}><DefaultInput value={this.state.complement} onChange={(v) => { this.setState({ complement: v }) }} label={'Complemento'}/></div>
                    </div>
                    <div style={{marginTop: 8}}/>
                    <IosSwitch label="Benefício Ativo" value="active" checked={this.state.active} onChange={(v) => { this.setState({ active: v }) }}/>
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.addPacient() }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    whatsApp(phone) {

        if (!phone.startsWith('55')) {

            phone = `55${phone}`;
        }
        
        window.open(`https://wa.me/${phone}`, '_blank');
    }

    async syncPacientBenefit(idUser, cpf) {

        if (cpf) {

            try {

                this.setState({ loading: true });
    
                let response = await Functions.request('POST', 'syncPacientBenefitByCpf', { cpf: cpf, id_user: idUser})
    
                if (response.status === 200) {

                    await this.getDocs();

                    this.setState({ loading: false });
                    toast.success('Paciente atualizado com sucesso');

                } else {

                    throw new Error('sync error');
                }
    
            } catch (error) {
    
                this.setState({ loading: false });
                toast.error('Houve um problema ao atualizar');
            }

        } else {

            toast.warn('Preencha o Cpf do paciente para sincronizar');
        }
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                {/* <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        <DefaultButton leftIcon={<AddIcon/>} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'}/>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        
                    </div>
                </div> */}

                <DefaultTable
                title={'Pacientes'}
                width={window.screen.width - 290}
                marginTop={10}
                actions={[
                    {
                        icon: WhatsAppIcon,
                        tooltip: 'Chamar no WhatsApp',
                        onClick: (event, rowData) => { this.whatsApp(rowData.phone) } 
                    },
                    {
                        icon: SyncIcon,
                        tooltip: 'Atualizar Benefício',
                        onClick: (event, rowData) => { this.syncPacientBenefit(rowData.id, rowData.cpf) } 
                    },
                ]}
                filtering={true}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Nome', field: 'name' },
                    { title: 'E-mail', field: 'email' },
                    { title: 'Cpf', field: 'cpf', render: rowData => <div>{rowData.cpf ? rowData.cpf.toString().replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : ''}</div> },
                    { title: 'Benefício Ativo', field: 'active', editable: false, type: 'boolean', render: rowData => <IosSwitch disabled={true} checked={rowData.active}/>, editComponent: props => (<IosSwitch disabled={true} checked={props.value} /> ) },
                    // { title: 'Login no App', field: 'app_login', editable: false, render: rowData => <IosSwitch disabled={true} checked={rowData.app_login}/> },
                    { title: 'Cep', field: 'zipcode' },
                    { title: 'Cidade', field: 'city' },
                    { title: 'Estado', field: 'state' },
                    { title: 'Rua', field: 'street' },
                    { title: 'Número', field: 'number' },
                    { title: 'Bairro', field: 'neighbourhood' },
                    { title: 'Complemento', field: 'complement' },
                    { title: 'Telefone', field: 'phone' },
                    { title: 'Data Cadastro', field: 'date', editable: false, render: rowData => <div>{moment(rowData.date.toDate ? rowData.date.toDate() : rowData.date).format('DD/MM/YYYY HH:mm')}</div> },
                ]}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    newData.date = oldData.date;

                    let prev = this.state.docs;
                    prev[prev.indexOf(oldData)] = newData;

                    this.setState({ docs: prev });

                    if (oldData.id) {

                        let update = {
                            name: newData.name,
                            email: newData.email,
                            phone: newData.phone,
                            cpf: newData.cpf,
                            zipcode: newData.zipcode,
                            street: newData.street,
                            number: newData.number,
                            neighbourhood: newData.neighbourhood,
                            complement: newData.complement,
                        };

                        await Firestore.update(update, 'user', newData.id);
                    }

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Aluno'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
