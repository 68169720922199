import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import ImagePicker from '../components/ImagePicker';
import PhotoIcon from '@material-ui/icons/Photo';
import CategoryIcon from '@material-ui/icons/Category';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

export default class CustomSpecialtiesAndCategoriesPage extends Component {

    state = {
        categories: [],
        specialties: [],
        name: '',
        images: [],
        addSpecialtyModal: false,
        addCategoryModal: false,
        photoModal: false
    }

    async getDocs() {
        this.setState({ loading: true });

        await this.getSpecialties();
        await this.getCategories();

        this.setState({ loading: false });
    }

    async getSpecialties() {
        let query = await Firestore.customQuery('specialty').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ specialties: docs });
    }

    async getCategories() {
        let query = await Firestore.customQuery('category').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ categories: docs });
    }

    async componentDidMount() {

        this.setState({ loading: true });

        await this.getDocs();

        this.setState({ loading: false });
    }

    async addSpecialty() {

        if (this.state.name) {

            try {
                this.setState({ loadingModal: true });

                let data = {
                    name: this.state.name,
                    id_company: SessionHelper.getData().id_company
                };

                await Firestore.insert(data, 'specialty');

                await this.setState({ loadingModal: false, addSpecialtyModal: false, name: '' });
                await this.getDocs();

                toast.success('Especialidade cadastrada com sucesso');

            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                console.log(error)

                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    async addCategory() {

        if (this.state.name && this.state.images[0]) {

            try {
                this.setState({ loadingModal: true });

                let data = {
                    name: this.state.name,
                    images: this.state.images,
                    id_company: SessionHelper.getData().id_company
                };

                await Firestore.insert(data, 'category');

                await this.setState({ loadingModal: false, addCategoryModal: false, name: '', images: [] });
                await this.getDocs();

                toast.success('Categoria cadastrada com sucesso');

            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                console.log(error)

                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn("Preencha todos os campos e insira uma imagem", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    addSpecialtyModal() {
        if (this.state.addSpecialtyModal) {
            return (
                <div>
                    <DefaultInput required label={'Nome'} onBlur={(v) => { this.setState({ name: v }) }} />
                    <div style={{ marginTop: 8 }} />

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.addSpecialty() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ addSpecialtyModal: false, images: [], name: '' }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    addCategoryModal() {
        if (this.state.addCategoryModal) {
            return (
                <div>
                    <DefaultInput required label={'Nome'} onBlur={(v) => { this.setState({ name: v }) }} />
                    <div style={{ marginTop: 8 }} />

                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <ImagePicker imageHeight={256} imageWidth={256} onUploadStart={() => { this.setState({ loadingModal: true }) }} onUploadEnd={() => { this.setState({ loadingModal: false }) }} onUpload={(files) => { this.setState({ images: files }); }} images={this.state.images} folder={'category_images'} multiple={false} label={'Imagem'} />
                    </div>

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.addCategory() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ addCategoryModal: false, images: [], name: '' }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    async updateImages(files) {
        this.setState({ images: files });
        Firestore.update({ images: files }, 'category', this.state.editId);
    }

    photoModal() {
        if (this.state.photoModal) {
            return (
                <div style={{}}>

                    <ImagePicker imageHeight={256} imageWidth={256} onUploadStart={() => { this.setState({ loadingModal: true }) }} onUploadEnd={() => { this.setState({ loadingModal: false }) }} onUpload={(files) => { this.updateImages(files) }} images={this.state.images} folder={'category_images'} multiple={false} />

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.setState({ photoModal: false, images: [] }) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '100%' }} variant={'contained'}>{'FECHAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>

                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <DefaultButton leftIcon={<CategoryIcon style={{ marginRight: 10 }} />} onClick={() => { this.setState({ addCategoryModal: true, name: '', images: [] }) }} title={'Nova Categoria'} />
                        <div style={{ marginLeft: 8 }} />
                        <DefaultButton leftIcon={<FormatListBulletedIcon style={{ marginRight: 10 }} />} onClick={() => { this.setState({ addSpecialtyModal: true, name: '', images: [] }) }} title={'Nova Especialidade'} />
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', justifyContent: 'space-between' }}>
                    <DefaultTable
                        title={'Categorias'}
                        marginTop={10}
                        width={`49%`}
                        actions={[
                            {
                                icon: PhotoIcon,
                                tooltip: 'Editar Imagens',
                                onClick: (event, rowData) => { this.setState({ photoModal: true, editId: rowData.id, images: rowData.images }) }
                            },
                        ]}
                        columns={[
                            { title: 'Id', field: 'id', hidden: true },
                            { title: 'Nome', field: 'name' },
                        ]}
                        data={this.state.categories}
                        onRowUpdate={async (oldData, newData) => {

                            newData.date = oldData.date;

                            let prev = this.state.categories;
                            prev[prev.indexOf(oldData)] = newData;

                            this.setState({ categories: prev });

                            if (oldData.id) {

                                let data = {
                                    name: newData.name,
                                };

                                await Firestore.update(data, 'category', oldData.id);
                            }

                            toast.success("Editado com sucesso", {
                                position: toast.POSITION.TOP_RIGHT
                            });

                            return prev;
                        }}
                    />
                    <DefaultTable
                        title={'Especialidades'}
                        marginTop={10}
                        width={`49%`}
                        columns={[
                            { title: 'Id', field: 'id', hidden: true },
                            { title: 'Nome', field: 'name' },
                        ]}
                        data={this.state.specialties}
                        onRowUpdate={async (oldData, newData) => {

                            newData.date = oldData.date;

                            let prev = this.state.specialties;
                            prev[prev.indexOf(oldData)] = newData;

                            this.setState({ specialties: prev });

                            if (oldData.id) {

                                let data = {
                                    name: newData.name,
                                };

                                await Firestore.update(data, 'specialty', oldData.id);
                            }

                            toast.success("Editado com sucesso", {
                                position: toast.POSITION.TOP_RIGHT
                            });

                            return prev;
                        }}
                        onRowDelete={async (oldData) => {

                            let prev = this.state.specialties;
                            prev.splice(prev.indexOf(oldData), 1);

                            this.setState({ specialties: prev });

                            if (oldData.id) {

                                await Firestore.delete('specialty', oldData.id);

                                toast.success("Removido com sucesso", {
                                    position: toast.POSITION.TOP_RIGHT
                                });

                                return prev;
                            }
                        }}
                    />
                </div>

                <DefaultModal loading={this.state.loadingModal} content={this.addSpecialtyModal()} title={'Nova Especialidade'} onClose={() => { this.setState({ addSpecialtyModal: false }) }} open={this.state.addSpecialtyModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.addCategoryModal()} title={'Nova Categoria'} onClose={() => { this.setState({ addCategoryModal: false }) }} open={this.state.addCategoryModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.photoModal()} title={'Imagem'} onClose={() => { this.setState({ photoModal: false, images: [] }) }} open={this.state.photoModal} />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
