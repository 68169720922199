import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultSelect from '../components/DefaultSelect';
import DefaultInput from '../components/DefaultInput';
import DefaultModal from '../components/DefaultModal';
import DefaultTable from '../components/DefaultTable';
import IosSwitch from '../components/IosSwitch';
import Colors from '../constants/Colors';
import { List, ListItem, Button, Tooltip } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import Functions from '../api/firebase/Functions';
import moment from 'moment';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import SessionHelper from '../helper/SessionHelper';
import firebase from 'firebase/app';
import { toast, ToastContainer } from 'react-toastify';

moment.locale('pt-br');

export default class PacientValidationPage extends Component {

    state = {
        loading: true,
        docs: [],
        search: '',
    }

    async componentDidMount() {
        await this.setState({ loading: false });
    }

    async handleSearch(text) {

        await this.setState({ search: text, loading: true, docs: [] });

        if (this.state.search.toString().length > 2) {

            const search = this.state.search.toString().replace(/[^0-9 ]/g, '');

            console.log(search)

            let results = await Functions.request('POST', 'getPacientByCpf', { search: search });

            this.setState({ docs: results.docs })

        } else if (this.state.search.toString().length === 0) {
            this.setState({ search: '', docs: [] });
        }

        await this.setState({ loading: false });
    }

    renderCardName(doc) {

        if (doc.name) {

            return `${doc.name} • ${doc.cpf.toString().replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}`

        } else {

            return doc.cpf.toString().replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
        }
    }

    renderBenefitStatus(status) {

        if (status) {

            return (
                <div style={{ paddingTop: 3, paddingBottom: 3, paddingLeft: 15, borderRadius: 8, paddingRight: 15, backgroundColor: Colors.success, boxShadow: Colors.boxShadow, width: 180 }}>
                    <p style={{ fontWeight: 'bold', color: 'white', textAlign: 'center' }}>{'BENEFÍCIO ATIVO'}</p>
                </div>
            )

        } else {

            return (
                <div style={{ paddingTop: 3, paddingBottom: 3, paddingLeft: 15, borderRadius: 8, paddingRight: 15, backgroundColor: Colors.error, boxShadow: Colors.boxShadow, width: 180 }}>
                    <p style={{ fontWeight: 'bold', color: 'white', textAlign: 'center' }}>{'BENEFÍCIO INATIVO'}</p>
                </div>
            )
        }
    }

    renderCardAddress(doc) {

        let address = [];

        if (doc.street) {
            address.push(doc.street);
        }

        if (doc.number) {
            address.push(doc.number);
        }

        if (doc.complemento) {
            address.push(doc.complemento);
        }

        if (doc.neighborhood) {
            address.push(doc.neighborhood);
        }

        let country = [];

        if (doc.city) {
            country.push(doc.city);
        }

        if (doc.state) {
            country.push(doc.state);
        }

        if (doc.zipcode) {
            country.push(doc.zipcode);
        }

        return `${address.join(', ')} ${country.length ? '•' : ''} ${country.join(', ')}`
    }

    renderCardContact(doc) {

        return `${doc.phone ? '+' + doc.phone : ''} ${doc.email && doc.phone ? '•' : ''} ${doc.email ? doc.email : ''}`
    }

    renderPacients() {

        return this.state.docs.map((doc, key) => {

            return (
                <ListItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: this.state.docs[key + 1] ? '1px solid lightgrey' : '', padding: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '60%' }}>
                        <div style={{ minWidth: 125 }}>
                            <img style={{ height: 100, marginRight: 20, width: 100, borderRadius: 100 / 2, boxShadow: Colors.boxShadow, border: '1px solid lightgrey' }} src={doc.photo ? doc.photo : process.env.PUBLIC_URL + '/empty_avatar.png'} />
                        </div>
                        <div style={{}}>
                            <div style={{ fontWeight: 'bold' }}>{this.renderCardName(doc)}</div>
                            <div style={{ color: 'grey', marginTop: 8 }}>{this.renderCardAddress(doc)}</div>
                            <div style={{ color: 'grey' }}>{this.renderCardContact(doc)}</div>
                            <div style={{ color: 'grey', fontSize: 12, marginTop: 20 }}>{`Cadastrado desde ${doc.date._seconds ? moment(new Date(doc.date._seconds * 1000)).format('DD/MM/YYYY HH:mm') : ''}`}</div>
                        </div>
                    </div>
                    <div>

                    </div>
                    <div style={{ marginRight: 20 }}>
                        {this.renderBenefitStatus(doc.active)}
                    </div>
                </ListItem>
            )
        });
    }

    renderEmpty() {
        return (
            <p style={{ textAlign: 'center', color: 'grey' }}>{'Nenhum paciente encontrado.'}</p>
        )
    }

    renderSearchEmpty() {
        return (
            <p style={{ textAlign: 'center', color: 'grey' }}>{`Busque um paciente por CPF para obter os detalhes do benefício Doctor'Home`}</p>
        )
    }

    handleShortcut = (evt) => {
        if (evt.key === "Enter") {
            this.handleSearch(evt.target.value);
        }
    }

    renderSearch() {

        return (
            <ListItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 20, borderBottom: '1px solid lightgrey' }}>
                <img style={{ height: 38, marginRight: 20 }} src={process.env.PUBLIC_URL + '/logo.png'} />
                <input value={this.state.search} onKeyDown={this.handleShortcut} onChange={(evt) => { this.setState({ search: evt.target.value }) }} style={{ width: '100%', border: '1px solid lightgrey', padding: 20, borderRadius: 10, backgroundColor: 'white', boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', fontSize: 16 }} placeholder={'Consulte um paciente CPF...'} />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {this.state.search ? <ClearIcon onClick={() => { this.setState({ search: '', loading: false, docs: [] }); }} style={{ fontSize: 34, cursor: 'pointer', marginRight: 10, marginLeft: 20 }} /> : null}
                    <SearchIcon style={{ fontSize: 34, cursor: 'pointer', marginLeft: 20 }} onClick={() => { this.handleSearch(this.state.search); }} />
                </div>
            </ListItem>
        )
    }


    render() {
        return (
            <div style={{ backgroundColor: Colors.primary, padding: 50, height: '100vh' }}>
                <List style={{ width: '100%', backgroundColor: '#FFFFFF', borderRadius: 5 }}>
                    {this.renderSearch()}
                    {this.renderPacients()}
                    {!this.state.docs.length && this.state.search.length < 1 ? this.renderSearchEmpty() : !this.state.docs.length && !this.state.loading ? this.renderEmpty() : null}
                    {this.state.loading ? <div><DefaultLoader /><div style={{ height: 300 }} /></div> : null}
                </List>
                <ToastContainer />
            </div>
        );
    }
}
