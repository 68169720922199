export default class LocalNotificationHelper {
    
    static async askPermission() {

        try {

            if (!window.Notification) {

                    throw new Error('Browser dont support notifications');
    
              } else {
                  
                if (Notification.permission !== 'denied') {
                  
                    return await Notification.requestPermission();
                }
            }

        } catch (error) {
            console.log(error);
        }
    }

    static send(title, body) {

        if (Notification.permission === 'granted') {

            const notification = new Notification(title, { body: body });
          
            notification.onclick = (e) => {
                e.preventDefault();
                window.focus();
                notification.close();
            }
        }
    }
}