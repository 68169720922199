import SessionHelper from "../../helper/SessionHelper";
import FunctionsConstants from "../../constants/Functions";

const api_key = FunctionsConstants.getPlatformKey();
const gateway = FunctionsConstants.getEndPoint();

export default class Functions {
    /**
     * @param {String} type The http method: POST, GET, PUT, DELETE
     * @param {String} route The route which your function is on the API
     * @param {Object} body The body object of the POST request, containing the desired data, authKey is added automatically
     * */
    static async request(type, route, body) {
        const user_id = SessionHelper.getFirebaseAuth() ? SessionHelper.getFirebaseAuth().uid : null;

        let response = {};

        body = body ? JSON.stringify(body) : null;

        try {
            const request = await fetch(`${gateway}/${route}`, {
                method: type,
                headers: {
                    'content-type': 'application/json',
                    'user_id': user_id,
                    'token': api_key
                },
                body: body
            });

            response = await request.json();

        } catch (error) {
            return null;
        }

        return response;
    }
}
