import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button, FormLabel, FormGroup, Select, MenuItem } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import CurrencyHelper from '../helper/CurrencyHelper';
import IosSwitch from '../components/IosSwitch';
import DefaultSelect from '../components/DefaultSelect';
import CurrencyInput from '../components/CurrencyInput';
import moment from 'moment';

export default class CouponPage extends Component {

    state = {
        docs: [],
        establishmentDocs: [],
        name: '',
        code: 'EXEMPLO_DE_CODIGO_2021',
        active: true,
        validOnAllEstablishment: true,
        establishments: [],
        validAbove: null,
        rawValidAbove: null,
        type: 'percentage',
        discount: '',
        rawDiscount: null,
        loading: true,
        loadingModal: false,
        addModal: false,
    }

    async getDocs() {

        let query = await Firestore.customQuery('coupon').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;
            data.valid_above = data.valid_above / 100;
            data.discount = data.type === 'percentage' ? data.discount : data.discount / 100;

            docs.push(data);
        });

        this.setState({ docs: docs });
    }

    async getEstablishmentDocs() {

        let query = await Firestore.customQuery('establishment').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ establishmentDocs: docs });
    }

    async componentDidMount() {

        this.setState({ loading: true });

        await this.getDocs();
        await this.getEstablishmentDocs();

        this.setState({ loading: false });
    }

    async addCoupon() {

        if (this.state.name && this.state.code) {

            try {

                this.setState({ loadingModal: true });

                let data = {
                    name: this.state.name,
                    code: this.state.code,
                    valid_above: this.state.rawValidAbove * 100,
                    type: this.state.type,
                    discount: this.state.type === 'percentage' ? this.state.discount : this.state.rawDiscount * 100,
                    valid_on_all_establishment: this.state.validOnAllEstablishment,
                    establishments: this.state.establishments,
                    active: this.state.active,
                    id_company: SessionHelper.getData().id_company,
                    date: new Date(),
                };

                await Firestore.insert(data, 'coupon');

                await this.setState({ loadingModal: false, addModal: false, name: '', code: 'EXEMPLO_DE_CODIGO_2021', active: true, validOnAllEstablishment: true, establishments: [], validAbove: null, rawValidAbove: null, type: 'percentage', discount: '', rawDiscount: null });
                await this.getDocs();

                toast.success('Cupom cadastrado com sucesso');

            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    renderEstablishmentSelect() {

        return (
            <div style={{ marginTop: 15, marginBottom: 15 }}>
                <DefaultSelect search={true} multiple={true} searchField={'name'} id={'stablishments'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ establishments: v.target.value }) }} value={this.state.establishments} docs={this.state.establishmentDocs} label={'Selecionar Estabelecimentos'}/>
            </div>
        )
    }

    renderValue() {

        if (this.state.type === 'percentage') {

            return (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 15 }}>
                    <div style={{ width: '30%' }}>
                        <DefaultInput required numeric label={'Valor de Desconto'} onBlur={(v) => { this.setState({ discount: v, rawDiscount: v }) }}/>
                    </div>
                    <div style={{ width: '70%', color: 'grey', fontWeight: 'bold', fontSize: 20, paddingLeft: 5 }}>{'%'}</div>
                </div>
            )

        } else {

            return (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 15 }}>
                    <div style={{ width: '30%' }}>
                        <CurrencyInput required label={'Valor de Desconto'} onChange={(values) => { const { formattedValue, value } = values; this.setState({ discount: formattedValue, rawDiscount: value }) }} value={this.state.discount} />
                    </div>
                </div>
            )
        }
    }

    addModal() {
        if (this.state.addModal) {
            return (
                <div>
                    <DefaultInput required label={'Nome'} onBlur={(v) => { this.setState({ name: v }) }}/>
                    <DefaultInput required label={'Código'} value={this.state.code} onChange={(v) => { this.setState({ code: v }) }}/>
                    <CurrencyInput required label={'Valido em compras acima de'} onChange={(values) => { const { formattedValue, value } = values; this.setState({ validAbove: formattedValue, rawValidAbove: value }) }} value={this.state.validAbove} />
                    
                    <div style={{paddingTop: 18}}>
                        <FormLabel component="legend">Tipo</FormLabel>
                        <FormGroup>
                            <Select value={this.state.type} onChange={(evt) => { this.setState({ type: evt.target.value, discount: '', rawDiscount: null }) }}>

                                <MenuItem value={'percentage'}>{'Porcentagem'}</MenuItem>
                                <MenuItem value={'value'}>{'Valor'}</MenuItem>

                            </Select>
                        </FormGroup>
                    </div>

                    { this.renderValue() }
                    
                    <IosSwitch fullWidth label={'Valido em todos os estabelecimentos'} checked={this.state.validOnAllEstablishment} onChange={(e) => { this.setState({ validOnAllEstablishment: e }) }} />
                    { !this.state.validOnAllEstablishment ? this.renderEstablishmentSelect() : null }
                    <IosSwitch label={'Ativo'} checked={this.state.active} onChange={(e) => { this.setState({ active: e }) }} />
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.addCoupon() }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    getEstablishmentsLookup() {
        let establishments = this.state.establishmentDocs;
        let lookup = {};

        establishments.forEach((establishment, key) => {
            lookup[establishment.id] = establishment.name;
        });

        return lookup;
    }

    renderEstablishments(establishments) {

        let text = '';

        if (establishments && establishments.length) {

            establishments.forEach((establishment, key) => {

                let doc = this.state.establishmentDocs.find(item => item.id === establishment);
                
                if (doc) {
    
                    text += doc.name;
                }
    
                if (establishments[key + 1]) {
                    text += ', ';
                }
            });
        }

        return text;
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>

                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        <DefaultButton onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'}/>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        
                    </div>
                </div>

                <DefaultTable
                title={'Cupons'}
                marginTop={10}
                width={window.screen.width - 290}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Nome', field: 'name' },
                    { title: 'Código', field: 'code' },
                    { title: 'Valido em compras acima de', field: 'valid_above', type: 'currency', currencySetting: { locale: 'brl', currencyCode: 'brl'}, editComponent: props => (<CurrencyInput value={props.value} onChange={(e) => { props.onChange(e.floatValue) }} />) },
                    { title: 'Tipo', field: 'type', editable: false, render: rowData => <div>{rowData.type === 'percentage' ? 'Porcentagem' : 'Valor'}</div> },
                    { title: 'Desconto', field: 'discount', editable: false, render: rowData => <div>{rowData.type === 'percentage' ? rowData.discount + '%' : CurrencyHelper.centsToMoney(rowData.discount * 100)}</div> },
                    { title: 'Valido em todos os estabelecimentos', field: 'valid_on_all_establishment', type: 'boolean', render: rowData => <IosSwitch disabled={true} checked={rowData.valid_on_all_establishment}/>, editComponent: props => (<IosSwitch checked={props.value} onChange={(e) => { props.onChange(e) }} />) },
                    { title: 'Estabelecimentos', field: 'establishments', editComponent: props => <DefaultSelect search={true} multiple={true} searchField={'name'} valueField={'id'} displayField={'name'} onChange={(v) => { props.onChange(v.target.value) }} value={props.value} docs={this.state.establishmentDocs}/>, render: rowData => this.renderEstablishments(rowData.establishments),},
                    { title: 'Ativo', field: 'active', type: 'boolean', render: rowData => <IosSwitch disabled={true} checked={rowData.active}/>, editComponent: props => (<IosSwitch checked={props.value} onChange={(e) => { props.onChange(e) }} />) },
                    { title: 'Data de Cadastro', editable: false, field: 'date', render: rowData => <div>{rowData.date ? moment(rowData.date.toDate ? rowData.date.toDate() : rowData.date).format('DD/MM/YYYY HH:mm') : ''}</div> },
                ]}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    newData.date = oldData.date;

                    let prev = this.state.docs;
                    prev[prev.indexOf(oldData)] = newData;

                    this.setState({ docs: prev });

                    if (oldData.id) {
                        
                        let data = {
                            name: newData.name,
                            code: newData.code,
                            valid_above: newData.valid_above * 100,
                            valid_on_all_establishment: newData.valid_on_all_establishment,
                            establishments: newData.establishments,
                            active: newData.active,
                        };

                        await Firestore.update(data, 'coupon', oldData.id);
                    }

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                onRowDelete={ async (oldData) => {

                    let prev = this.state.docs;
                    prev.splice(prev.indexOf(oldData), 1);
                    
                    this.setState({ docs: prev });

                    if (oldData.id) {

                        await Firestore.delete('coupon', oldData.id);

                        toast.success("Removido com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        return prev;
                    }
                }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Cupom'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
