import firebase from 'firebase/app'
import 'firebase/firestore';
import { GeoFirestore } from 'geofirestore';
import Geohash from '../../helper/Geohash';

export default class Firestore {

    static async insert(data, collection, doc = null) {

        if (doc) {

            return await firebase.firestore().collection(collection).doc(doc).set(data);

        } else {

            return await firebase.firestore().collection(collection).doc().set(data);
        }
    }

    static async update(data, collection, doc) {
        return await firebase.firestore().collection(collection).doc(doc).update(data);
    }

    static async getDoc(collection, doc) {
        return await firebase.firestore().collection(collection).doc(doc).get();
    }

    static async getCollection(collection, where, order, limit) {

        let query = await firebase.firestore().collection(collection);

        if (where) {
            query = await query.where(where[0], where[1], where[2]);
        }

        if (order) {
            query = await query.orderBy(order[0], order[1]);
        }

        if (limit) {
            query = await query.limit(limit);
        }

        return query.get();
    }

    static customQuery(collection) {
        return firebase.firestore().collection(collection);
    }

        /**
    * To insert with geohashes, data cant have property "l" neither "g", they'll be overwritten
    *
    * @param   {Object} data - the data you wish to insert.
    * @param   {string} collection - the firestore collection
    * @param   {number} lat - Longitude in degrees.
    * @param   {number} lng - Latititude in degrees.
    *
    * @example
    *     Firestore.geoInsert({a: 123}, 'company', 52.205, 0.119); 
    */
    static async geoInsert(data, collection, lat, lng) {
        data.g = {
            geohash: Geohash.encode(lat, lng),
            geopoint: new firebase.firestore.GeoPoint(lat, lng)
        }

        return await firebase.firestore().collection(collection).doc().set(data);
    }

    /**
     * To update with geohashes, data cant have property "l" neither "g", they'll be overwritten
     *
     * @param   {Object} data - the data you wish to insert.
     * @param   {string} collection - the firestore collection
     * @param   {string} doc - the document ref "id"
     * @param   {number} lat - Longitude in degrees.
     * @param   {number} lng - Latititude in degrees.
     *
     * @example
     *     Firestore.geoUpdate({a: 123}, 'company', 'dxdAdoas12732usaida', 52.205, 0.119); 
     */
    static async geoUpdate(data, collection, doc, lat, lng) {
        data.g = {
            geohash: Geohash.encode(lat, lng),
            geopoint: new firebase.firestore.GeoPoint(lat, lng)
        }

        return await firebase.firestore().collection(collection).doc(doc).update(data);
    }

    static async getPaginatedCollection(collection, from, limit, order) {
        let query = firebase.firestore().collection(collection);

        if (from) {
            query = query.startAfter(from).limit(limit);
        } else {
            query = query.limit(limit);
        }

        if (order) {
            query = query.orderBy(order[0], order[1]);
        }

        return await query.get();
    }

    static async searchPaginatedCollection(collection, from, limit, where) {
        if (from) {
            return await firebase.firestore().collection(collection).orderBy(where.field)
                .startAt(where.term)
                .endAt(`${where.term}\uf8ff`).startAfter(from).limit(limit).get();
        } else {
            return await firebase.firestore().collection(collection).orderBy(where.field)
                .startAt(where.term)
                .endAt(`${where.term}\uf8ff`).limit(limit).get();
        }
    }

    static async getSortedCollection(collection, sorting, page) {
        return await firebase.firestore().collection(collection).get().orderBy(sorting.field, sorting.order).startAfter(page.current).limit(10);
    }

    static async getSearchedCollection(collection, field, operator, term, limit) {
        return await firebase.firestore().collection(collection).where(field, operator, term).limit(limit).get();
    }

    static async delete(collection, doc) {
        if (!collection || !doc) return;

        return await firebase.firestore().collection(collection).doc(doc).delete();
    }

    static async getId(collection) {
        return await firebase.firestore().collection(collection).doc().id;
    }
}