import React, { Component } from 'react';
import DefaultLoader from '../DefaultLoader';
import { Card, IconButton, Tooltip, Grid } from '@material-ui/core';
import Firestore from '../../api/firebase/Firestore';
import moment from 'moment';
import { Doughnut, Bar } from 'react-chartjs-2';
import RefreshIcon from '@material-ui/icons/Refresh';
import Colors from '../../constants/Colors';

export default class HomePage extends Component {

    state = {
        loading: true,
        pacientDocs: [],
        pacientChart: [],
        newAccountsChart: {
            labels: ['Hoje', 'Essa semana', 'Esse mês', 'Ano todo'],
            datasets: [
                {
                    label: 'Número de matrículas',
                    backgroundColor: '#FF6384',
                    borderWidth: 1,
                    data: [],
                }
            ]
        },
    }

    async getPacients() {

        let query = await Firestore.customQuery('user').where('type', '==', 'pacient').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ pacientDocs: docs });
    }

    async componentDidMount() {

        this.setState({ loading: true });

        await this.getPacients();

        await this.pacientChart();
        await this.newAccountsChart();

        this.setState({ loading: false });
    }

    async pacientChart() {

        let data = {
            active: 0,
            inactive: 0,
        };

        this.state.pacientDocs.forEach((pacient, key) => {

            if (pacient.active) {

                data.active ++;

            } else if (pacient.active === false) {

                data.inactive ++;
            }
        });

        this.setState({ pacientChart: [data.active, data.inactive] });
    }

    async newAccountsChart() {

        let counts = {
            today: 0,
            week: 0,
            month: 0,
            year: 0,
        };

        this.state.pacientDocs.forEach((pacient, key) => {

            let date = moment(pacient.date.toDate());
            let actualDate = moment();

            if (actualDate.isSame(date, 'day')) {
                counts.today ++;
            }

            if (actualDate.isSame(date, 'week')) {
                counts.week ++;
            }

            if (actualDate.isSame(date, 'month')) {
                counts.month ++;
            }

            if (actualDate.isSame(date, 'year')) {
                counts.year ++;
            }
        });


        let data = {
            labels: ['Hoje', 'Essa semana', 'Esse mês', 'Ano todo'],
            datasets: [
                {
                    label: 'Cadastros',
                    backgroundColor: '#FFCE56',
                    borderWidth: 1,
                    data: [counts.today, counts.week, counts.month, counts.year],
                }
            ]
        };

        this.setState({ newAccountsChart: data });
    }

    render() {

        return this.state.loading ? <DefaultLoader /> : (

            <div style={{ width: '100%' }}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Card style={{ padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', height: 375, boxShadow: Colors.boxShadow }}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ fontSize: 20 }}>{'Pacientes'}</div>
                                <Tooltip onClick={async () => { await this.getPacients(); await this.pacientChart(); }} title="Atualizar"><IconButton><RefreshIcon style={{}} /></IconButton></Tooltip>
                            </div>

                            <Doughnut
                                height={190}
                                width={500}
                                options={{ maintainAspectRatio: true }}
                                data={{
                                    labels: [
                                        'Ativos',
                                        'Inativos',
                                    ],
                                    datasets: [{
                                        data: this.state.pacientChart,
                                        backgroundColor: [
                                            '#36A2EB',
                                            'lightgrey'
                                        ],

                                    }]
                                }}
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card style={{ padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', height: 375, boxShadow: Colors.boxShadow }}>

                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ fontSize: 20 }}>{'Cadastros no App'}</div>
                                <Tooltip onClick={async () => { await this.getPacients(); await this.newAccountsChart(); }} title="Atualizar"><IconButton><RefreshIcon style={{}} /></IconButton></Tooltip>
                            </div>

                            <Bar
                                height={190}
                                width={500}
                                options={{ maintainAspectRatio: true, scales: { yAxes: [{ ticks: { beginAtZero: true, precision: 0 } }] } }}
                                data={this.state.newAccountsChart} />
                        </Card>
                    </Grid>
                </Grid>
          </div>
        )
    }
}