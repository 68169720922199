import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import DefaultLoader from '../components/DefaultLoader';
import DefaultInput from '../components/DefaultInput';
import GeographicHelper from '../helper/GeographicHelper';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import Colors from '../constants/Colors';
import Maps from '../constants/Maps';
import SearchIcon from '@material-ui/icons/Search';

import GpsFixed from '@material-ui/icons/GpsFixed';

export class LocationPicker extends Component {
    state = {
        address: {},
        loading: true,
        loadingPlace: false,
        zoom: 14,
        center: {
            lat: -27.066484,
            lng: -52.628256
        }
    }

    componentDidMount = async () => {
        this.setState({ loading: true });

        if (this.props.initialCenter) this.setState({ center: this.props.initialCenter });

        this.setState({ loading: false });
    }

    getLocation = async () => {
        await GeographicHelper.getCurrentPosition(async (geodata) => {
            if (geodata) {
                this.setState({ center: { lat: geodata.lat, lng: geodata.lng } });
                await GeographicHelper.reverseGeocode(geodata, this.setAddress);

                if (this.props.callback) {
                    setTimeout(() => {
                        this.props.callback({ coords: this.state.center, address: this.state.address });
                    }, 1000);
                }
            }

        });
    }

    setAddress = (data) => {
        this.setState({ address: data });
    }

    handleDragEnd = async (props, map) => {
        await this.setState({ center: { lat: map.center.lat(), lng: map.center.lng() } });

        if (this.props.callback) {
            await GeographicHelper.reverseGeocode(this.state.center, this.setAddress);
            setTimeout(() => {
                this.props.callback({ coords: this.state.center, address: this.state.address });
            }, 1000);
        }
    }

    handleZoom = async (props, map) => {
        await this.setState({ center: { lat: map.center.lat(), lng: map.center.lng() } });

        if (this.props.callback) {
            setTimeout(() => {
                this.props.callback({ coords: this.state.center, address: this.state.address });
            }, 1000);
        }
    }

    fetchPlaces = () => {
        let google = this.props.google;

        const input = document.getElementById("pac-input");
        const searchBox = new google.maps.places.SearchBox(input);

        searchBox.addListener("places_changed", async () => {
            const place = searchBox.getPlaces();

            if (place.length == 0) {
                return;
            }

            this.setState({ center: { lat: place[0].geometry.location.lat(), lng: place[0].geometry.location.lng() }, loadingPlace: true });

            if (this.props.callback) {
                await GeographicHelper.reverseGeocode(this.state.center, this.setAddress);
                setTimeout(() => {
                    this.props.callback({ coords: this.state.center, address: this.state.address });
                }, 1000);
            }

            this.setState({ loadingPlace: false });
        });
    }

    renderMapDiv() {
        return (
            <div style={{ width: '100%', height: '400px', position: 'relative', marginTop: '10px', marginBottom: '80px' }}>
                <div style={{ marginTop: 10, marginBottom: 10, display: 'flex', flexDirection: 'row' }}>
                    {this.state.loadingPlace ? <DefaultLoader size={28} color={'#0c0c0c'} css={{ display: 'flex', marginTop: 5, marginRight: 10, justifyContent: 'center', alignItems: 'center' }} /> : <div style={{ display: 'flex', height: '100%', justifyContent: 'center', marginRight: 5 }}><SearchIcon style={{ marginTop: 25, fontSize: 28, color: '#0c0c0c' }} /></div>}
                    <DefaultInput id="pac-input" label={'Busque um endereço ou estabelecimento...'} type={'text'} />
                </div>
                <Map
                    className="map"
                    ref={map => { mapRef = map }}
                    google={this.props.google}
                    zoom={this.state.zoom}
                    initialCenter={this.props.initialCenter ? this.props.initialCenter : this.state.center}
                    center={this.state.center}
                    onDragend={this.handleDragEnd}
                    onZoomChanged={this.handleZoom}
                    minZoom={3}
                    onReady={this.fetchPlaces}
                >
                    <Marker
                        position={this.state.center} />
                    <Button className={"map-button"} style={styles.googleMapsGetLocationButton} onClick={() => { this.getLocation() }}><GpsFixed style={{ color: '#0c0c0c' }} /></Button>
                </Map>
            </div>
        );
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : this.renderMapDiv();
    }
}

let mapRef;

const styles = {
    container: {
        padding: 25,
    },
    googleMapsGetLocationButton: {
        position: 'absolute',
        left: 10,
        bottom: 40,
        width: 'auto',
        height: 40,
        backgroundColor: 'white',
        borderRadius: 0,
        color: Colors.primary
    }
}

export default GoogleApiWrapper({
    apiKey: (Maps.key),
    language: 'pt-br'
})(LocationPicker)