import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import Colors from '../constants/Colors';

export default class components extends Component {

	getBackgroundColor() {

		return !this.props.disabled ? (this.props.color ? this.props.color : Colors.primary) : 'lightgrey';
	}

	getColor() {

		return 'white';
	}

	render() {

		return (
			<Button
			disabled={this.props.disabled ? this.props.disabled : false}
			onClick={() => { this.props.onClick() }}
			style={{ width: this.props.width ? this.props.width : 'auto', fontWeight: 'bold', backgroundColor: this.getBackgroundColor(), color: this.getColor(), display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}
			variant="contained" >
				{this.props.leftIcon && this.props.leftIcon}
				{this.props.title}
				{this.props.rightIcon && this.props.rightIcon}
			</Button>
		)
	}
}
