import React from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Platform from '../constants/Platform';
import { Divider, Drawer, Hidden, Collapse, Button } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ChevronRight from "@material-ui/icons/ChevronRight";
import PieChartIcon from "@material-ui/icons/PieChart";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import SettingsInputCompositeIcon from "@material-ui/icons/SettingsInputComposite";
import RssFeedIcon from "@material-ui/icons/RssFeed";
import InfoIcon from "@material-ui/icons/Info";
import LockIcon from '@material-ui/icons/Lock';
import ForumIcon from '@material-ui/icons/Forum';
import PeopleIcon from "@material-ui/icons/People";
import ImageIcon from "@material-ui/icons/Image";
import FavoriteIcon from "@material-ui/icons/Favorite";
import SendIcon from "@material-ui/icons/Send";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Switch, Route, Link, BrowserRouter, Redirect } from "react-router-dom";
import firebase from 'firebase/app'
import Colors from '../constants/Colors';
import HomePage from '../page/HomePage';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import SessionHelper from '../helper/SessionHelper';
import UserPage from "../page/UserPage";
import DoctorPage from "../page/DoctorPage";
import PermissionPage from "../page/PermissionPage";
import NewsletterPage from "../page/NewsletterPage";
import CouponPage from "../page/CouponPage";
import DefaultModal from '../components/DefaultModal';
import NotificationCenter from "../components/NotificationCenter";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStethoscope, faBriefcaseMedical, faClinicMedical } from '@fortawesome/free-solid-svg-icons'
import EstablishmentPage from "../page/EstablishmentPage";
import NotificationPage from "../page/NotificationPage";
import PacientPage from "../page/PacientPage";
import MessagePage from "../page/MessagePage";
import ImagesPage from "../page/ImagesPage";
import IntegrationPage from "../page/IntegrationPage";
import NotFoundPage from "../page/NotFoundPage";
import CustomSpecialtiesAndCategoriesPage from "../page/CustomSpecialtiesAndCategoriesPage";
import CategoryIcon from '@material-ui/icons/Category';

const drawerWidth = 240;

export default class navigation extends React.Component {

    logout() { firebase.auth().signOut(); window.location.href = '/' }

    state = {
        actualRoute: null,
        settingsMenuOpen: false,
        integrationsMenuOpen: false,
        aboutModal: false,
    }

    componentDidMount() {

        this.setState({ actualRoute: window.location.pathname });
    }

    handleRouteChange() {
        setTimeout(() => {
            this.setState({ actualRoute: window.location.pathname });
        }, 10)
    }

    handleClick(menu) {
        let state = this.state;
        state[menu] = !state[menu];

        this.setState(state);
    }

    aboutModal() {
        if (this.state.aboutModal) {
            return (
                <div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <img style={{ height: 150, marginLeft: 'auto', marginRight: 'auto', marginBottom: 8 }} src={process.env.PUBLIC_URL + '/logo_full.png'} />
                        <div style={{ textAlign: 'center', fontSize: 11, color: 'grey', marginTop: 10 }}>
                            <div style={{ borderBottom: '1px solid lightgrey', marginLeft: 'auto', marginRight: 'auto', paddingBottom: 15, width: '70%' }}></div>
                        </div>
                        <div style={{ textAlign: 'center', fontSize: 11, color: 'grey', marginTop: 25 }}>Plataforma Dr' Home | Versão {Platform.version}</div>
                        <div style={{ textAlign: 'center', fontSize: 11, color: 'grey' }}>Copyright © 2021 Todos os direitos reservados</div>

                        <div style={{ fontSize: 11, color: 'grey', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 25 }}>Desenvolvido com <FavoriteIcon style={{ marginLeft: 5, marginRight: 5, color: 'red' }} /> por <a target={'_blank'} href={'https://easydata.net.br/'} style={{ paddingLeft: 3, color: 'blue' }}>EasyData Softwares LTDA</a></div>
                        <div style={{ textAlign: 'center', fontSize: 11, color: 'grey' }}>CNPJ 35.698.234/0001-39</div>
                    </div>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.setState({ aboutModal: false }) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '100%' }} variant={'contained'}>{'FECHAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div style={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" style={{ width: `calc(100% - ${drawerWidth}px)`, marginLeft: drawerWidth, backgroundColor: Colors.primary }}>
                    <Toolbar>
                        <Typography style={{ fontWeight: 'bold' }} variant="h6" noWrap>
                            Plataforma Admin
                        </Typography>
                        <div style={{ flexGrow: 1 }} />
                        <NotificationCenter />
                    </Toolbar>
                </AppBar>
                <BrowserRouter forceRefresh={false}>
                    <nav style={{ width: drawerWidth, flexShrink: 0, }} aria-label="mailbox folders">
                        <Hidden xsDown implementation="css">
                            <Drawer
                                PaperProps={{ style: { width: drawerWidth } }}
                                variant="permanent"
                                open>
                                <div>
                                    <div />
                                    <Divider />
                                    <List>
                                        <ListItem onClick={() => { this.setState({ actualRoute: '/' }) }} component={Link} button to={'/'}>
                                            <img style={{ height: 32, marginBottom: 8 }} src={process.env.PUBLIC_URL + '/logo.png'} />
                                        </ListItem>
                                        
                                        {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.dashboard ?

                                            <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/' && true} style={this.state.actualRoute === '/' ? { backgroundColor: Colors.primary } : {}} component={Link} button to={'/'}>
                                                <ListItemIcon><PieChartIcon style={this.state.actualRoute === '/' ? { color: '#fff' } : {}} /></ListItemIcon>
                                                <ListItemText style={this.state.actualRoute === '/' ? { color: '#fff' } : {}} primary={'Dashboard'} />
                                            </ListItem>

                                        : null}

                                        {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.pacient ?

                                        <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/pacient' && true} style={this.state.actualRoute === '/pacient' ? { backgroundColor: Colors.primary } : {}} component={Link} button to={'/pacient'}>
                                            <ListItemIcon><FontAwesomeIcon icon={faStethoscope} style={this.state.actualRoute === '/pacient' ? { color: '#fff', fontSize: 23, paddingLeft: 3 } : {fontSize: 23, paddingLeft: 3}} /></ListItemIcon>
                                            <ListItemText style={this.state.actualRoute === '/pacient' ? { color: '#fff' } : {}} primary={'Pacientes'} />
                                        </ListItem>

                                        : null}

                                        {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.establishment ?

                                        <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/establishment' && true} style={this.state.actualRoute === '/establishment' ? { backgroundColor: Colors.primary } : {}} component={Link} button to={'/establishment'}>
                                            <ListItemIcon><FontAwesomeIcon icon={faClinicMedical} style={this.state.actualRoute === '/establishment' ? { color: '#fff', fontSize: 24, paddingLeft: 3 } : {fontSize: 24, paddingLeft: 3}} /></ListItemIcon>
                                            <ListItemText style={this.state.actualRoute === '/establishment' ? { color: '#fff' } : {}} primary={'Conveniados'} />
                                        </ListItem>

                                        : null}

                                        {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.newsletter ?

                                            <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/newsletter' && true} style={this.state.actualRoute === '/newsletter' ? { backgroundColor: Colors.primary } : {}} component={Link} button to={'/newsletter'}>
                                                <ListItemIcon><RssFeedIcon style={this.state.actualRoute === '/newsletter' ? { color: '#fff' } : {}} /></ListItemIcon>
                                                <ListItemText style={this.state.actualRoute === '/newsletter' ? { color: '#fff' } : {}} primary={'Notícias'} />
                                            </ListItem>

                                        : null}

                                        {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.notification ?

                                            <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/notification' && true} style={this.state.actualRoute === '/notification' ? { backgroundColor: Colors.primary } : {}} component={Link} button to={'/notification'}>
                                                <ListItemIcon><SendIcon style={this.state.actualRoute === '/notification' ? { color: '#fff' } : {}} /></ListItemIcon>
                                                <ListItemText style={this.state.actualRoute === '/notification' ? { color: '#fff' } : {}} primary={'Notificações'} />
                                            </ListItem>

                                        : null}

                                        {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.coupon ?

                                            <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/coupon' && true} style={this.state.actualRoute === '/coupon' ? { backgroundColor: Colors.primary } : {}} component={Link} button to={'/coupon'}>
                                                <ListItemIcon><LocalOfferIcon style={this.state.actualRoute === '/coupon' ? { color: '#fff' } : {}} /></ListItemIcon>
                                                <ListItemText style={this.state.actualRoute === '/coupon' ? { color: '#fff' } : {}} primary={'Cupons'} />
                                            </ListItem>

                                        : null}

                                        {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.images ?

                                            <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/images' && true} style={this.state.actualRoute === '/images' ? { backgroundColor: Colors.primary } : {}} component={Link} button to={'/images'}>
                                                <ListItemIcon><ImageIcon style={this.state.actualRoute === '/images' ? { color: '#fff' } : {}} /></ListItemIcon>
                                                <ListItemText style={this.state.actualRoute === '/images' ? { color: '#fff' } : {}} primary={'Imagens'} />
                                            </ListItem>

                                        : null}

                                        {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.integration ?

                                            <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/integration' && true} style={this.state.actualRoute === '/integration' ? { backgroundColor: Colors.primary } : {}} component={Link} button to={'/integration'}>
                                                <ListItemIcon><SettingsInputCompositeIcon style={this.state.actualRoute === '/integration' ? { color: '#fff' } : {}} /></ListItemIcon>
                                                <ListItemText style={this.state.actualRoute === '/integration' ? { color: '#fff' } : {}} primary={'Integrações'} />
                                            </ListItem>

                                        : null}

                                        <ListItem selected={this.state.settingsMenuOpen ? true : false} button onClick={() => { this.handleClick('settingsMenuOpen') }}>
                                            <ListItemIcon>
                                                <SettingsIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Configurações" />
                                            {this.state.settingsMenuOpen ? <ExpandMore /> : <ChevronRight />}
                                        </ListItem>
                                        <Collapse in={this.state.settingsMenuOpen} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>

                                                {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.user ?

                                                    <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/user' && true} style={this.state.actualRoute === '/user' ? { backgroundColor: Colors.primary } : {}} component={Link} button to={'/user'}>
                                                        <ListItemIcon><PeopleIcon style={this.state.actualRoute === '/user' ? { color: '#fff' } : {}} /></ListItemIcon>
                                                        <ListItemText style={this.state.actualRoute === '/user' ? { color: '#fff' } : {}} primary={'Usuários'} />
                                                    </ListItem>

                                                : null}

                                                {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.specialties_and_categories ?

                                                    <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/specialties_and_categories' && true} style={this.state.actualRoute === '/specialties_and_categories' ? { backgroundColor: Colors.primary } : {}} component={Link} button to={'/specialties_and_categories'}>
                                                        <ListItemIcon><CategoryIcon style={this.state.actualRoute === '/specialties_and_categories' ? { color: '#fff' } : {}} /></ListItemIcon>
                                                        <ListItemText style={this.state.actualRoute === '/specialties_and_categories' ? { color: '#fff' } : {}} primary={'Categorias e Especialidades'} />
                                                    </ListItem>

                                                : null}

                                                { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.permission ?

                                                <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/permission' && true} style={ this.state.actualRoute === '/permission' ? {backgroundColor: Colors.primary} : {}} component={Link} button to={'/permission'}>
                                                    <ListItemIcon><LockIcon style={ this.state.actualRoute === '/permission' ? {color: '#fff'} : {}} /></ListItemIcon>
                                                    <ListItemText style={ this.state.actualRoute === '/permission' ? {color: '#fff'} : {}} primary={'Permissões'} />
                                                </ListItem>

                                                : null }

                                                <ListItem component={Link} button onClick={() => { this.setState({ aboutModal: true }) }}>
                                                    <ListItemIcon><InfoIcon /></ListItemIcon>
                                                    <ListItemText primary={'Sobre'} />
                                                </ListItem>

                                            </List>
                                        </Collapse>

                                        <ListItem component={Link} button onClick={() => { this.logout() }} to={'/'}>
                                            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                                            <ListItemText primary={'Sair'} />
                                        </ListItem>
                                    </List>
                                </div>
                            </Drawer>
                        </Hidden>
                    </nav>

                    <main style={{ flexGrow: 1, paddingTop: 60 }}>
                        <Switch>
                            {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.dashboard ? <Route exact path="/" render={() => <HomePage />} /> : null}
                            {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.user ? <Route path="/user" render={() => <UserPage />} /> : null}
                            {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.permission ? <Route path="/permission" render={() => <PermissionPage />} /> : null}
                            {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.newsletter ? <Route path="/newsletter" render={() => <NewsletterPage />} /> : null}
                            {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.coupon ? <Route path="/coupon" render={() => <CouponPage />} /> : null}
                            {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.establishment ? <Route path="/establishment" render={() => <EstablishmentPage />} /> : null}
                            {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.notification ? <Route path="/notification" render={() => <NotificationPage />} /> : null}
                            {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.pacient ? <Route path="/pacient" render={() => <PacientPage />} /> : null}
                            {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.images ? <Route path="/images" render={() => <ImagesPage />} /> : null}
                            {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.integration ? <Route path="/integration" render={() => <IntegrationPage />} /> : null}
                            {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.specialties_and_categories ? <Route path="/specialties_and_categories" render={() => <CustomSpecialtiesAndCategoriesPage />} /> : null}
                            {/* {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.newsletter ? <Route path="/message" render={() => <MessagePage />} /> : null} */}

                            <Route path="/404" render={() => <NotFoundPage height={'calc(100vh - 60px)'}/> } />
                            <Redirect to="/404" />
                        </Switch>
                    </main>
                </BrowserRouter>
                <ToastContainer style={{ paddingTop: 100 }} />
                <DefaultModal loading={this.state.loadingModal} content={this.aboutModal()} title={'Sobre'} onClose={() => { this.setState({ aboutModal: false }) }} open={this.state.aboutModal} />
            </div>
        )
    }
}
