import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button } from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Firestore from '../api/firebase/Firestore';
import moment from 'moment';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import RichTextEditor from 'react-rte';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import AddIcon from '@material-ui/icons/Add';

export default class NewsletterPage extends Component {

    state = {
        docs: [],
        loading: true,
        loadingModal: false,
        editModal: false,
        edit: null,
        editId: null,
        deleteModal: false,
        deleteId: null,
        addModal: false,
        editor: RichTextEditor.createEmptyValue(),
        editorHtml: RichTextEditor.createEmptyValue(),
        title: '',
        inputError: false,
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('newsletter').where('id_company', '==', SessionHelper.getData().id_company).orderBy('date', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    componentDidMount() {

        this.getDocs();
    }

    editItem(doc) { this.setState({ editModal: true, edit: doc, editId: doc.id, title: doc.title, editor: RichTextEditor.createValueFromString(doc.body, 'html'), editorHtml: doc.body }) }

    onChange = (value) => { this.setState({editor: value, editorHtml: value.toString('html')}) };

    async addPost() {

        if (this.state.editor && this.state.title) {

            let data = {
                title: this.state.title,
                body: this.state.editorHtml,
                id_company: SessionHelper.getData().id_company,
                date: new Date(),
            };

            try {

                this.setState({ loadingModal: true });

                await Firestore.insert(data, 'newsletter');

                toast.success("Cadastrado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.setState({ loadingModal: false, addModal: false, editor: RichTextEditor.createEmptyValue(), editorHtml: RichTextEditor.createEmptyValue() });
                await this.getDocs();

            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }
        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    async editPost() {

        if (this.state.editor && this.state.title && this.state.editId) {

            let data = {
                title: this.state.title,
                body: this.state.editorHtml,
            };

            try {

                this.setState({ loadingModal: true });

                await Firestore.update(data, 'newsletter', this.state.editId);

                toast.success("Editado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.setState({ loadingModal: false, editModal: false, editor: RichTextEditor.createEmptyValue(), editorHtml: RichTextEditor.createEmptyValue(), title: '', editId: null });
                await this.getDocs();

            } catch (error) {
                toast.error("Houve um problema ao editar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }
        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    addModal() {
        return (
            <div>
                <DefaultInput required onError={(error, msg) => { this.setState({ inputError: error }) }} onBlur={(v) => { this.setState({ title: v }) }} label={'Título'}/>
                <RichTextEditor
                editorStyle={{height: 500}}
                value={this.state.editor}
                onChange={this.onChange}
                toolbarConfig={{
                    // Optionally specify the groups to display (displayed in the order listed).
                    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'IMAGE_BUTTON', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
                    INLINE_STYLE_BUTTONS: [
                      {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
                      {label: 'Italico', style: 'ITALIC'},
                      {label: 'Sublinhado', style: 'UNDERLINE'}
                    ],
                    BLOCK_TYPE_DROPDOWN: [
                      {label: 'Normal', style: 'unstyled'},
                      {label: 'Título Grande', style: 'header-one'},
                      {label: 'Título Médio', style: 'header-two'},
                      {label: 'Título Pequeno', style: 'header-three'}
                    ],
                    BLOCK_TYPE_BUTTONS: [
                      {label: 'Lista', style: 'unordered-list-item'},
                      {label: 'Lista Numerada', style: 'ordered-list-item'}
                    ],
                }}
                />
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.addPost() }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    editModal() {

        if (this.state.edit) {

            return (
                <div>
                    <DefaultInput value={this.state.title} onChange={(v) => { this.setState({ title: v }) }} label={'Título'}/>
                    <RichTextEditor
                    editorStyle={{height: 500}}
                    value={this.state.editor}
                    onChange={this.onChange}
                    toolbarConfig={{
                        // Optionally specify the groups to display (displayed in the order listed).
                        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'IMAGE_BUTTON', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
                        INLINE_STYLE_BUTTONS: [
                          {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
                          {label: 'Italico', style: 'ITALIC'},
                          {label: 'Sublinhado', style: 'UNDERLINE'}
                        ],
                        BLOCK_TYPE_DROPDOWN: [
                          {label: 'Normal', style: 'unstyled'},
                          {label: 'Título Grande', style: 'header-one'},
                          {label: 'Título Médio', style: 'header-two'},
                          {label: 'Título Pequeno', style: 'header-three'}
                        ],
                        BLOCK_TYPE_BUTTONS: [
                          {label: 'Lista', style: 'unordered-list-item'},
                          {label: 'Lista Numerada', style: 'ordered-list-item'}
                        ],
                    }}
                    />
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.editPost() }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ editModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        <DefaultButton leftIcon={<AddIcon/>} onClick={() => { this.setState({ addModal: true, editor: RichTextEditor.createEmptyValue(), title: '' }) }} title={'Adicionar'}/>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        
                    </div>
                </div>  
                
                <DefaultTable
                title={'Feed de Notícias'}
                marginTop={10}
                width={window.screen.width - 290}
                actions={[
                    {
                        tooltip: 'Conteúdo da Publicação',
                        icon: InsertDriveFileIcon,
                        onClick: (event, rowData) => { this.editItem(rowData) },
                    }
                ]}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Título', field: 'title' },
                    { title: 'Data Publicação', editable: false, field: 'date', render: rowData => moment(rowData.date.toDate()).format('DD/MM/YYYY HH:mm') },
                ]}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    let prev = this.state.docs;
                    prev[prev.indexOf(oldData)].title = newData.title;

                    this.setState({ docs: prev });

                    if (oldData.id) {
                        
                        let update = {
                            title: newData.title,
                        };

                        await Firestore.update(update, 'newsletter', oldData.id);
                    }

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                onRowDelete={ async (oldData) => {

                    let prev = this.state.docs;
                    prev.splice(prev.indexOf(oldData), 1);
                    
                    this.setState({ docs: prev });

                    if (oldData.id) {
                        await Firestore.delete('newsletter', oldData.id);
                    }

                    toast.success("Removido com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                />

                <DefaultModal width={1200} loading={this.state.loadingModal} content={this.addModal()} title={'Nova publicação'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
                <DefaultModal width={1200} loading={this.state.loadingModal} content={this.editModal()} title={'Editar publicação'} onClose={() => { this.setState({ editModal: false }) }} open={this.state.editModal}/>

            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
