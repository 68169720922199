export default [
    {
        name: 'Alergia e imunologia',
        id: 'Alergia e imunologia',
    },
    {
        name: 'Anestesiologia',
        id: 'Anestesiologia',
    },
    {
        name: 'Cardiologia',
        id: 'Cardiologia',
    },
    {
        name: 'Cirurgia geral',
        id: 'Cirurgia geral',
    },
    {
        name: 'Clínica médica',
        id: 'Clínica médica',
    },
    {
        name: 'Dermatologia',
        id: 'Dermatologia',
    },
    {
        name: 'Endocrinologia e metabologia',
        id: 'Endocrinologia e metabologia',
    },
    {
        name: 'Endoscopia',
        id: 'Endoscopia',
    },
    {
        name: 'Gastroenterologia',
        id: 'Gastroenterologia',
    },
    {
        name: 'Geriatria',
        id: 'Geriatria',
    },
    {
        name: 'Ginecologia e obstetrícia',
        id: 'Ginecologia e obstetrícia',
    },
    {
        name: 'Hematologia e hemoterapia',
        id: 'Hematologia e hemoterapia',
    },
    {
        name: 'Infectologia',
        id: 'Infectologia',
    },
    {
        name: 'Medicina da família',
        id: 'Medicina da família',
    },
    {
        name: 'Medicina do trabalho',
        id: 'Medicina do trabalho',
    },
    {
        name: 'Medicina intensiva',
        id: 'Medicina intensiva',
    },
    {
        name: 'Medicina legal e perícia médica',
        id: 'Medicina legal e perícia médica',
    },
    {
        name: 'Nefrologia',
        id: 'Nefrologia',
    },
    {
        name: 'Neurocirurgia',
        id: 'Neurocirurgia',
    },
    {
        name: 'Neurologia',
        id: 'Neurologia',
    },
    {
        name: 'Nutrologia',
        id: 'Nutrologia',
    },
    {
        name: 'Oftalmologia',
        id: 'Oftalmologia',
    },
    {
        name: 'Oncologia',
        id: 'Oncologia',
    },
    {
        name: 'Ortopedia',
        id: 'Ortopedia',
    },
    {
        name: 'Otorrinolaringologia',
        id: 'Otorrinolaringologia',
    },
    {
        name: 'Patologia',
        id: 'Patologia',
    },
    {
        name: 'Pediatria',
        id: 'Pediatria',
    },
    {
        name: 'Pneumologia',
        id: 'Pneumologia',
    },
    {
        name: 'Psiquiatria',
        id: 'Psiquiatria',
    },
    {
        name: 'Reumatologia',
        id: 'Reumatologia',
    },
    {
        name: 'Urologia',
        id: 'Urologia',
    },
]