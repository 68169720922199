import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import moment from 'moment';
import IosSwitch from '../components/IosSwitch';
import DefaultSelect from '../components/DefaultSelect';
import NotificationHelper from '../helper/NotificationHelper';
import LoopIcon from '@material-ui/icons/Loop';

export default class NotificationPage extends Component {

    state = {
        docs: [],
        userDocs: [],
        adminUserDocs: [],
        title: '',
        body: '',
        allUsers: true,
        users: [],
        loading: true,
        loadingModal: false,
        addModal: false,
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('notification_sended').where('id_company', '==', SessionHelper.getData().id_company).orderBy('date', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    async getUsers() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('user').where('id_company', '==', SessionHelper.getData().id_company).where('type', '==', 'pacient').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            if (data.notification_token && data.notification_token.data) {
                docs.push(data);
            }
        });

        this.setState({ userDocs: docs, loading: false });
    }

    async getAdminUsers() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('user').where('id_company', '==', SessionHelper.getData().id_company).where('type', '==', 'admin').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ adminUserDocs: docs, loading: false });
    }

    async componentDidMount() {

        await this.getDocs();
        await this.getUsers();
        await this.getAdminUsers();
    }

    async sendNotifications(doc, allUsers = false, users = [], title, body) {

        let notificationTitle = title ? title : this.state.title;
        let notificationBody = body ? body : this.state.body;

        if (allUsers) {

            this.state.userDocs.forEach((user, key) => {

                if (user && user.notification_token && user.notification_token.data) {
                    NotificationHelper.send(notificationTitle, notificationBody, {}, user.notification_token.data, user.id, doc);
                }
            });

        } else {
            
            if (users.length) {

                users.forEach((idUser, key) => {

                    let user = this.state.userDocs.find(item => item.id === idUser);
    
                    if (user && user.notification_token && user.notification_token.data) {
                        NotificationHelper.send(notificationTitle, notificationBody, {}, user.notification_token.data, user.id, doc);
                    }
                });
            }
        }
    }

    async addNotification() {

        if (this.state.title && this.state.body) {

            try {

                this.setState({ loadingModal: true });

                let data = {
                    title: this.state.title,
                    body: this.state.body,
                    all_users: this.state.allUsers,
                    users: this.state.users,
                    created_by: SessionHelper.getFirebaseAuth().uid,
                    date: new Date(),
                    id_company: SessionHelper.getData().id_company,
                };

                let doc = await Firestore.getId('notification_sended');

                await this.sendNotifications(doc, data.all_users, data.users);
                await Firestore.insert(data, 'notification_sended', doc);

                toast.success("Notificação enviada com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.setState({ loadingModal: false, addModal: false, title: '', body: '', users: [], allUsers: true });
                await this.getDocs();

            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    renderUserSelection() {

        return (
            <div style={{ marginTop: 8 }}>
                <DefaultSelect search={true} multiple={true} searchField={'name'} id={'users'} valueField={'id'} render={(doc) => { return doc.name ? `${doc.name} (${doc.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")})` : doc.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") }} onChange={(v) => { this.setState({ users: v.target.value }) }} value={this.state.users} docs={this.state.userDocs} label={'Selecionar Usuários'}/>
            </div>
        )
    }

    renderNotificationPreview() {

        return (

            <div style={{ width: '100%', boxShadow: Colors.boxShadow, padding: 10, marginTop: 10, display: 'flex', flexDirection: 'column', border: '1px solid lightgrey', borderRadius: 8, flexWrap: 'wrap' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 8, width: '100%' }}>
                    <img style={{ height: 25 }} src={process.env.PUBLIC_URL + '/icon.png'} />
                    <div style={{ marginLeft: 5, color: 'grey', fontWeight: 400 }}>DR' HOME</div>
                    <div style={{ flexGrow: 1 }}/>
                    <div style={{ color: 'grey', fontWeight: 400 }}>{'há 3m'}</div>
                </div>
                <div style={{ fontWeight: 'bold' }}>{this.state.title || 'Título'}</div>
                <div style={{ }}>{this.state.body || 'Corpo'}</div>
            </div>
        )
    }

    addModal() {
        if (this.state.addModal) {
            return (
                <div>
                    <DefaultInput label={'Título'} onChange={(v) => { this.setState({ title: v }) }}/>
                    <DefaultInput label={'Corpo'} onChange={(v) => { this.setState({ body: v }) }}/>
                    <IosSwitch label={'Enviar para todos os usuários'} checked={this.state.allUsers} onChange={(e) => { this.setState({ allUsers: e }) }} />
                    { !this.state.allUsers ? this.renderUserSelection() : null }
                    <p>{'Pré-visualização'}</p>
                    { this.renderNotificationPreview() }
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.addNotification() }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        <DefaultButton onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'}/>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        
                    </div>
                </div>

                <DefaultTable
                title={'Notificações'}
                marginTop={10}
                width={window.screen.width - 290}
                actions={[
                    {
                        icon: LoopIcon,
                        tooltip: 'Enviar Novamente',
                        onClick: (event, rowData) => { this.sendNotifications(rowData.id, rowData.all_users, rowData.users, rowData.title, rowData.body); toast.success('Notificações enviadas'); } 
                    },
                ]}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Título', field: 'title' },
                    { title: 'Corpo', field: 'body' },
                    { title: 'Enviar para todos os usuários', field: 'all_users', render: rowData => <IosSwitch disabled={true} checked={rowData.all_users}/>, editComponent: props => (<IosSwitch checked={props.value} onChange={(e) => { props.onChange(e) }} />), },
                    { title: 'Usuários', field: 'users', render: rowData => <div>{rowData.all_users ? 'Todos os usuários' : `${rowData.users.length} usuários selecionados`}</div>, editComponent: props => <DefaultSelect render={(doc) => { return doc.name ? `${doc.name} (${doc.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")})` : doc.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") }}  search={true} multiple={true} searchField={'name'} valueField={'id'} onChange={(v) => { props.onChange(v.target.value) }} value={props.value} docs={this.state.userDocs}/> },
                    { title: 'Data de Cadastro', editable: false, field: 'date', render: rowData => <div>{rowData.date ? moment(rowData.date.toDate ? rowData.date.toDate() : rowData.date).format('DD/MM/YYYY HH:mm') : ''}</div> },
                ]}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    newData.date = oldData.date;

                    let prev = this.state.docs;
                    prev[prev.indexOf(oldData)] = newData;

                    this.setState({ docs: prev });

                    if (oldData.id) {
                        
                        let data = {
                            title: newData.title,
                            body: newData.body,
                            sector: newData.sector || '',
                            all_users: newData.all_users,
                            users: newData.users,
                        };

                        await Firestore.update(data, 'notification_sended', oldData.id);
                    }

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                onRowDelete={ async (oldData) => {

                    let prev = this.state.docs;
                    prev.splice(prev.indexOf(oldData), 1);
                    
                    this.setState({ docs: prev });

                    if (oldData.id) {

                        await Firestore.delete('notification_sended', oldData.id);

                        toast.success("Removido com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Nova Notificação'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
